// TODO: Is it possible to import 'toast' from Vue without needing to pass it as an arg?

export default function share(url, toast) {
  console.log('SHARE', url);

  if ('share' in navigator) {
    navigator.share({ url }).catch((err) => {
      console.warn('share aborted', err);
    });
  } else {
    navigator.clipboard.writeText(url);

    toast.init({
      duration: 2500,
      position: 'bottom-right',
      offsetX: 10,
      offsetY: 58,
      color: 'dark',
      message: 'Copied map link.',
    });
  }
}
