import { createApp } from 'vue';

import store from './store.js';
store.dispatch('load');
import Countly from 'countly-sdk-web';
import VueCountly from 'vue-countly';
import App from './app.vue';
import './registerServiceWorker.js';
import router from './router.js';
import { VuesticPlugin } from 'vuestic-ui';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

// import 'vuestic-ui/dist/vuestic-ui.css';

import '../public/css/theme.css';

createApp(App)
  .use(router)
  .use(store)
  .use(VueCountly, Countly, {
    app_key: 'd6993e589b09a4af03346e74b363016b032ef666',
    url: 'https://analytics.phobosrising.co',
    device_id: store.state.session,
  })
  .use(VuesticPlugin, {
    components: {
      VaButton: {
        rounded: false,
        size: 'medium',
      },
    },

    colors: {
      primary: '#0a9745', // --color-green-700
      secondary: '#4299e1', // --color-blue-500
      success: '#4aed8f', // --color-green-500
      info: '#63b3ed', // --color-blue-300
      danger: '#e53e3e', // --color-red-500
      warning: '#dd6b20', // --color-orange-700
      gray: '#7e8d85', // --color-grey-500
      dark: '#22333b', // --color-grey-700
    },
  })
  .mount('main#app');
