<template>
  <section class="screen">
    <div class="padder">
      <p>
        Check your email for a link to login to Map Buddy. Once you tap that link you'll be logged in to Map Buddy!
      </p>

      <p>
        It can take a minute or two for your email to arrive. If it still hasn't arrived, you can go back to the
        previous screen and try again.
      </p>

      <p>
        If the link in the email doesn't work then you can copy and paste the Auth Code from the email into the field
        below.
      </p>
    </div>

    <div class="bottom-group">
      <div>
        <va-form tag="form" @submit.prevent="submitAuthCode">
          <va-input
            class="text-input mb-1"
            v-model="authcode"
            label="Auth Code"
            outline
            :maxLength="8"
            :rules="[(value) => value.length === 8 || `Auth code should be 8 characters`]"
            :error-messages="codeInputErrorMessage"
            :error="!!codeInputErrorMessage"
            autocapitalize="characters"
          />

          <div class="terms">
            By signing up you agree to the
            <a href="https://mapbuddy.app/terms-of-service.html" target="_blank">Terms of Service</a>.
          </div>

          <div class="button-bar">
            <va-button to="/account-create" outline color="gray" class="half-width">Go Back</va-button>
            <va-button
              type="submit"
              class="half-width"
              v-bind:disabled="authcode.length !== 8 || sendingAuthCodeRequest"
              icon="mark_email_read"
              >Login</va-button
            >
          </div>
        </va-form>
      </div>
    </div>
  </section>
</template>

<script>
import { authenticateAuthCode } from '@/lib/authenticate.js';

export default {
  name: 'AuthCodeScreen',

  data() {
    return {
      authcode: '',
      sendingAuthCodeRequest: false,
      codeInputErrorMessage: '',
    };
  },

  methods: {
    // TODO: redundant with authorize.vue
    async submitAuthCode() {
      const token = this.authcode;
      this.sendingAuthCodeRequest = true;
      try {
        await authenticateAuthCode(token, this.$router);
      } catch (err) {
        this.sendingAuthCodeRequest = false;
        if (err.status_code === 404) {
          this.codeInputErrorMessage = 'Code has expired, was used, or is incorrect.';
        } else {
          this.$vaToast.init({
            message: err.message,
            position: 'bottom-right',
            offsetX: 10,
            offsetY: 58,
            color: 'warning',
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.note {
  padding: 10px 0;
  font-style: italic;
}
.note a {
  color: var(--color-green-pigment);
}
.terms {
  font-size: 14px;
  text-align: center;
  padding: 0 0 10px 0;
  color: var(--color-xanadu);
}
p + p {
  margin-top: 20px;
}
</style>
