// return value measured in meters
export function distance(p1, p2) {
  const radlat1 = (Math.PI * p1.lat) / 180;
  const radlat2 = (Math.PI * p2.lat) / 180;
  const theta = p1.lon - p2.lon;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515 * 1.609344 * 1000;
  return dist;
}
