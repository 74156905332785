import { createRouter, createWebHistory } from 'vue-router';

import Countly from 'countly-sdk-web';

import AccountCreate from '@/views/account-create.vue';
import AccountCreateApple from '@/views/account-create-apple.vue';
import Authorize from '@/views/authorize.vue';
import AuthCode from '@/views/auth-code.vue';
import ChooseUsername from '@/views/choose-username.vue';
import MapView from '@/views/map-view.vue';
import MapCreate from '@/views/map-create.vue';
import Search from '@/views/search.vue';
import PostCreate from '@/views/post-create.vue';
import Settings from '@/views/settings.vue';
import Root from '@/views/root.vue';
import Missing from '@/views/missing.vue';
import Tutorial from '@/views/tutorial.vue';
import BlockedUsers from '@/views/blocked-users.vue';
import Favorites from '@/views/favorites.vue';
import PostView from '@/views/post-view.vue';
import Profile from '@/views/profile.vue';
import QrRedirect from '@/views/qr-redirect.vue';

import store from '@/store.js';

/**
 * noauth: This route cannot be opened if the user is authenticated
 * mustauth: This route can only be opened if the user is authenticated
 * navbar: navigation bar visiblity
 *   truthy: show navbar
 *   falsey: hide navbar
 *   string: highlight this particular button
 */
const routes = [
  {
    path: '/',
    name: 'Root',
    component: Root,
    meta: {
      noauth: false,
      navbar: true,
      title: 'Map Buddy',
    },
  },

  {
    path: '/tutorial',
    name: 'Tutorial',
    component: Tutorial,
    meta: {
      noauth: false,
      navbar: true,
      title: 'Map Buddy Tutorial',
    },
  },

  {
    path: '/account-create',
    name: 'AccountCreate',
    component: AccountCreate,
    meta: {
      noauth: false, // has built-in redirect logic since it's the first loaded view
      navbar: true,
      title: 'Create Account or Login',
    },
  },

  {
    path: '/account-create/apple',
    name: 'AccountCreateApple',
    component: AccountCreateApple,
    meta: {
      noauth: false,
      navbar: true,
      title: 'Verifying Apple Account',
    },
  },

  {
    path: '/authcode',
    name: 'AuthCode',
    component: AuthCode,
    meta: {
      noauth: true,
      navbar: true,
      title: 'Confirm Email Account',
    },
  },

  {
    path: '/authorize/:token',
    name: 'Authorize',
    component: Authorize,
    meta: {
      noauth: true,
      navbar: false,
      title: 'Authorize',
    },
  },

  {
    path: '/username',
    name: 'ChooseUsername',
    component: ChooseUsername,
    meta: {
      mustauth: true,
      navbar: false,
      title: 'Choose a Username',
    },
  },

  {
    path: '/map-create',
    name: 'MapCreate',
    component: MapCreate,
    meta: {
      mustauth: true,
      navbar: 'search',
      title: 'Create New Map',
    },
  },

  {
    // 2022-05-02: URLs creatd before today used a different format
    // /channel/foo
    // /channel/foo/@37.7902537,-122.3919992,16z
    path: '/channel/:mapName/:viewport(@-?\\d*\\.{0,1}\\d+,-?\\d*\\.{0,1}\\d+,\\d{1,2}z)?',
    name: 'OldChannelRedirect',
    redirect: (to) => {
      if (to.params.viewport) {
        return { name: 'MapView', path: `/map/${to.params.mapName}/${to.params.viewport}` };
      } else {
        return { name: 'MapView', path: `/map/${to.params.mapName}` };
      }
    },
  },

  {
    // /map/foo
    // /map/foo/@37.7902537,-122.3919992,16z
    path: '/map/:mapName/:viewport(@-?\\d*\\.{0,1}\\d+,-?\\d*\\.{0,1}\\d+,\\d{1,2}z)?',
    name: 'MapView',
    component: MapView,
    meta: {
      mustauth: false,
      navbar: 'home',
      showMenuButton: true,
      title: 'Map',
      isMapView: true, // TODO: hacky solution to a race condition in map-view.vue
    },
  },

  {
    // /map-embed/foo
    // /map-embed/foo/@37.7902537,-122.3919992,16z
    path: '/map-embed/:mapName/:viewport(@-?\\d*\\.{0,1}\\d+,-?\\d*\\.{0,1}\\d+,\\d{1,2}z)?',
    name: 'MapViewEmbed',
    component: MapView,
    meta: {
      embedded: true, // Update app.vue if this route changes as it looks for "/map-embed" in URL
      mustauth: false,
      navbar: false,
      title: 'Map',
      isMapView: true, // TODO: hacky solution to a race condition in map-view.vue
    },
  },

  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      mustauth: false,
      navbar: 'search',
      title: 'Map Search',
      showMenuButton: true,
    },
  },

  {
    path: '/post-create',
    name: 'PostCreate',
    component: PostCreate,
    meta: {
      mustauth: true,
      navbar: 'post-create',
      title: 'New Post',
    },
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      mustauth: false,
      navbar: 'settings',
      title: 'Settings',
    },
  },

  {
    path: '/blocked-users',
    name: 'BlockedUsers',
    component: BlockedUsers,
    meta: {
      mustauth: false,
      navbar: 'settings',
      title: 'Blocked Users',
    },
  },

  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    meta: {
      mustauth: true,
      navbar: 'favorites',
      title: 'Favorite Posts',
    },
  },

  {
    path: '/post/:mapName/:postId',
    name: 'PostView',
    component: PostView,
    meta: {
      mustauth: false,
      navbar: 'favorites',
      title: 'Viewing a Post',
    },
  },

  {
    path: '/profile/:username',
    name: 'Profile',
    component: Profile,
    meta: {
      navbar: true,
      title: 'Profile View',
    },
  },

  {
    path: '/qr/:code',
    name: 'QrRedirect',
    component: QrRedirect,
    meta: {
      navbar: true,
      title: 'QR Code Redirect',
    },
  },

  {
    path: '/:catchAll(.*)',
    name: 'Missing',
    component: Missing,
    meta: {
      noauth: false,
      navbar: true,
      title: 'Error: Missing Screen',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  console.info('🠖', to.path);

  store.commit('disableMenu');

  if (to.path === '/' && to.hash.startsWith('#/')) {
    console.warn('appears to be an old URL, redirecting to new URL.');
    return to.hash.substr(1); // translate hash into normal path
  }

  if (to.meta.mustauth && !store.state.user) {
    console.warn('Must be logged in to visit this screen. Redirecting to account creation screen.');
    return '/account-create?warn';
  }

  if (to.meta.noauth && store.state.user && !from.name) {
    console.warn('Should not be logged in to visit screen, but seems like reload, redirecting to home.');
    return '/';
  }

  if (to.meta.noauth && store.state.user) {
    console.warn('Cannot be logged in to visit this screen');
    return false;
  }

  store.state.navbar = to.meta.navbar;
  store.state.showMenuButton = to.meta.showMenuButton;
  store.commit('setTitle', { title: to.meta.title });

  Countly.track_view(to.fullPath);

  return true;
});

export default router;
