<template>
  <va-modal
    :mobile-fullscreen="false"
    :hide-default-actions="true"
    v-bind:title="title"
    @ok="$emit('confirm')"
    v-model="show"
  >
    <slot> </slot>
    <template #footer>
      <va-button style="color: var(--va-gray); background: white" @click="$emit('cancel')">
        {{ cancelButtonLabel }}
      </va-button>
      <va-button @click="$emit('confirm')" :color="confirmButtonColor">
        {{ confirmButtonLabel }}
      </va-button>
    </template>
  </va-modal>
</template>

<script>
export default {
  name: 'ModalPrompt',
  props: {
    cancelButtonLabel: String,
    confirmButtonLabel: String,
    confirmButtonColor: String,
    title: String,
  },
  emits: ['confirm', 'cancel'],
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style>
body .va-modal {
  position: absolute;
}
</style>