<template>
  <section class="screen">
    <div class="tutorial-panels" v-bind:class="{ 'five-panel': !installed }">
      <div class="tutorial-panel">
        <h3>Welcome to Map Buddy!</h3>

        <p>
          Map Buddy (formerly <em>Radar Chat</em>) lets you create, discover, and subscribe to interactive maps.
        </p>

        <p>
          Find food trucks, garage sales, good coffee, and more! Plan your next friendly scavenger hunt or leave
          surprise notes for your loved ones.
        </p>

        <p>
          There really isn't anything like Map Buddy out there! This quick tutorial will walk you through some basic
          features.
        </p>
      </div>

      <div class="tutorial-panel">
        <h3>Post Visibility</h3>

        <p>
          Messages, locations, and other things on the map are called posts. Many posts can only be seen if you are near
          them.
        </p>

        <div class="image">
          <img src="/img/tutorial/post-range.gif" width="280" height="100" />
        </div>

        <p>Some posts, called beacons, are always visible no matter how far away you are.</p>

        <div class="image">
          <img src="/img/tutorial/beacons.gif" width="280" height="100" />
        </div>

        <p>There's a hidden world of posts all around you!</p>
      </div>

      <div class="tutorial-panel">
        <h3>Post Loudness</h3>

        <p>You can choose the &ldquo;loudness&rdquo; when posting.</p>

        <div class="image">
          <img src="/img/tutorial/post-radius.gif" width="280" height="100" />
        </div>

        <p>
          A quiet post, called a <span class="map-level-circle">wsp</span> whisper, can only seen if you're right next
          to it. A whisper lasts a long time.
        </p>

        <p>
          A loud post, called a <span class="map-level-circle">scm</span> scream, can be seen by a whole town. But, a
          scream will expire much quicker than a whisper.
        </p>

        <p>
          There are five levels of loudness to choose from. They are ordered from quietest to loudness, and are
          displayed as <span class="map-level-circle">wsp</span> (whisper),
          <span class="map-level-circle">mtr</span> (mutter), <span class="map-level-circle">tlk</span> (talk),
          <span class="map-level-circle">sht</span> (shout), and <span class="map-level-circle">scm</span> (scream).
        </p>
      </div>

      <div class="tutorial-panel">
        <h3>Maps</h3>

        <p>
          Posts are organized into maps. Maps are ways to keep similar posts in the same place. For example, the
          <strong>#foodtrucks</strong> map can help you find food trucks while the <strong>#garage-sales</strong> map
          can help you find garage sales.
        </p>

        <p>
          You can subscribe to different maps to make them all visible on your home screen at once. There is a special
          map called <strong>#global</strong> that everyone is subscribed to by default.
        </p>

        <hr />

        <p>
          That's it for the tutorial! Press the
          <va-icon @click="$router.push('/')" class="material-icons">public</va-icon> icon on the bottom of the screen
          to view posts around you. Press the
          <va-icon @click="$router.push('/search')" class="material-icons">travel_explore</va-icon> icon to search for
          new maps to join. Or, press
          <va-icon @click="$router.push('/post-create')" class="material-icons">maps_ugc</va-icon> to create your very
          first post!
        </p>
      </div>

      <div class="tutorial-panel" v-if="!installed">
        <h3>How to install Map Buddy</h3>

        <p v-if="platform === 'android'">Install Map Buddy from the Google Play Store:</p>
        <p v-if="platform === 'ios'">Install Map Buddy from the App Store:</p>

        <div class="image">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=chat.radar.app.twa"
            ><img
              v-if="platform === 'android' || platform === 'unknown'"
              src="/img/tutorial/install-android.png"
              width="280"
              height="89"
          /></a>
          <a target="_blank" href="https://apps.apple.com/app/radar-chat-local-discovery/id1599975217"
            ><img
              v-if="platform === 'ios' || platform === 'unknown'"
              src="/img/tutorial/install-ios.png"
              width="280"
              height="89"
          /></a>
        </div>

        <p>Or, add Map Buddy to your homescreen:</p>

        <div class="image">
          <img v-if="platform === 'ios'" src="/img/tutorial/install-steps-ios.gif" width="280" height="280" />
          <img v-else src="/img/tutorial/install-steps-android.gif" width="280" height="280" />
        </div>
      </div>
    </div>
    <div class="bottom-indicator">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
  </section>
</template>

<script>
import { INSTALLED, PLATFORM } from '@/lib/platform.js';

export default {
  name: 'TutorialScreen',

  data() {
    return {
      installed: INSTALLED,
      platform: PLATFORM,
    };
  },

  created() {
    this.$store.commit('finishTutorial');
  },

  methods: {
    jump(index) {
      this.$router.push(`/tutorial/${index}`);
    },
  },
};
</script>

<style scoped>
p,
hr {
  margin-bottom: calc(var(--gap-standard) * 2);
}

.image {
  text-align: center;
}

.image img {
  margin: var(--gap-standard) auto;
  border-radius: 8px;
  background-color: #eee;
  border: 1px solid #ddd;
}

section.screen {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.tutorial-panels {
  display: flex;
  height: 100%;
  width: 400vw;
}

.tutorial-panels.five-panel {
  width: 500vw;
}

.tutorial-panel {
  flex: 1;
  width: 100vw;
  height: 100%;
  padding: var(--gap-standard);
  scroll-snap-align: start;
}

.bottom-indicator {
  pointer-events: none;
  position: fixed;
  bottom: calc(var(--nav-bar-height));
  left: 0px;
  right: 0px;
  padding: var(--gap-standard);
  text-align: center;
}

body.platform-ios .bottom-indicator {
  bottom: calc(var(--nav-bar-height) + 10px);
}

.bottom-indicator .circle {
  width: 5px;
  height: 5px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 50%;
  background-color: var(--color-grey-500);
}
</style>
