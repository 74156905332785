<template>
  <section class="screen">
    <div class="padder">
      <p>
        This screen allows you to manage the users that you've blocked. To block a user, visit their profile and tap the
        <va-icon class="material-icons" size="small">person_off</va-icon> button.
      </p>
    </div>

    <div class="padder">
      <h3>Blocked Users</h3>

      <va-alert color="dark" v-if="!isBlocking"> You are not currently blocking any users. </va-alert>
      <va-alert color="dark" v-else> Tap a user in this list to unblock them. </va-alert>

      <va-list>
        <va-list-item
          v-for="(username, userId) in blocked"
          :key="userId"
          class="list-item"
          @click="unblock({ username, userId })"
        >
          <va-list-item-section avatar>
            <va-icon name="person" color="primary" class="list-item-icon" />
          </va-list-item-section>

          <va-list-item-section>
            <va-list-item-label> @{{ username }} </va-list-item-label>
          </va-list-item-section>
        </va-list-item>
      </va-list>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BlockedUsersScreen',

  setup() {
    // all data is client side (for now)
  },

  data() {
    return {
      blocked: this.$store.state.blocked,
    };
  },

  computed: {
    isBlocking() {
      return !!Object.keys(this.$store.state.blocked).length;
    },
  },

  methods: {
    unblock({ userId, username }) {
      this.$store.commit('unblockUser', userId);

      this.$vaToast.init({
        message: `User @${username} is no longer blocked on this device.`,
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'info',
      });
    },
  },
};
</script>

<style scoped>
.list-item {
  padding: 5px 0;
  border-bottom: 1px solid var(--color-grey-300);
}

.list-item:first-child {
  border-top: 1px solid var(--color-grey-300);
}

.list-item-icon {
  border: 1px solid var(--color-grey-500);
  border-radius: 50%;
  padding: 5px;
}
</style>
