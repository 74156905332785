<template>
  <section class="screen">
    <div class="padder" v-if="loaded && !posts.length">
      <va-alert color="dark"> You don't have any favorite posts. </va-alert>
    </div>

    <va-list v-if="posts.length">
      <va-list-item
        v-for="post in posts"
        :key="post.id"
        class="list-item"
        @click="this.$router.push(`/post/${post.ch}/${post.id}`)"
      >
        <va-list-item-section avatar>
          <va-icon v-if="!post.icon" name="place" color="primary" class="list-item-icon" />
          <va-icon v-else color="primary" size="small" class="list-item-icon emoji">{{ post.icon }}</va-icon>
        </va-list-item-section>

        <va-list-item-section>
          <va-list-item-label>{{ post.message }}</va-list-item-label>

          <va-list-item-label caption>
            @{{ post.username }} &#8226; #{{ post.ch }}
            <span v-if="typeof post.replies === 'number'">
              &#8226; {{ post.replies }} {{ post.replies === 1 ? 'reply' : 'replies' }}</span
            >
          </va-list-item-label>
        </va-list-item-section>
      </va-list-item>
    </va-list>
  </section>
</template>

<script>
import network from '@/network.js';

export default {
  name: 'FavoritesScreen',

  data() {
    return {
      loaded: false,
      posts: [],
    };
  },

  async created() {
    this.posts = await network.thread.listFavorites();
    this.loaded = true;
  },

  methods: {},
};
</script>

<style scoped>
/* TODO: map-list.vue copypasta */
.va-list {
  padding: 0px;
}

.list-item {
  padding: 5px 0;
  border-bottom: 1px solid var(--color-grey-300);
}

.list-item-icon {
  border: 1px solid var(--color-grey-500);
  border-radius: 50%;
  padding: 5px;
  width: 36px;
  height: 36px;
}

.list-item-icon.emoji {
  text-align: center;
}

.details {
  font-size: 13px;
  color: var(--color-gunmetal);
}
</style>
