import store from '@/store.js';
import network from '@/network.js';

export default async function afterLogin(router) {
  const firstVisitedMap = store.state.firstVisitedMap;

  // using replace instead of push as users shouldn't navigate to pre-login screens
  if (firstVisitedMap) {
    router.replace(`/map/${firstVisitedMap}`);
  } else {
    router.replace('/map/home');
  }

  const checkins = await network.checkin.getMyCheckins();
  store.commit('setCheckins', checkins);
}
