<template>
  <div v-if="visible" class="shroud" @click="close"></div>
  <va-sidebar v-if="visible" width="80vw">
    <div class="padder">
      <h1 class="selectable">#{{ name }}</h1>
      <p class="description selectable">{{ description }}</p>

      <div v-if="icon" class="facts">Icon: {{ icon }}</div>
      <div v-if="!isVirtual && memberCount >= 0" class="facts">Members: {{ memberCount.toLocaleString() }}</div>
      <div v-if="!isVirtual && created" class="facts mb-1">Created: {{ new Date(created).toLocaleDateString() }}</div>

      <div class="button-bar">
        <va-button
          class="full-width"
          v-if="website"
          size="medium"
          icon="public"
          color="secondary"
          :href="website"
          target="_blank"
          >Open Website</va-button
        >

        <va-button
          class="full-width"
          icon="bookmark_add"
          color="secondary"
          v-if="isLoggedIn && name && isSubscribable && !isMember"
          @click="subscribe"
        >
          Subscribe to Map
        </va-button>

        <va-button
          class="full-width"
          v-else-if="isLoggedIn && name && isSubscribable && isMember"
          icon="bookmark_remove"
          color="danger"
          @click="unsubscribe"
        >
          Unsubscribe from Map
        </va-button>

        <va-button v-if="isSubscribable" icon="map" @click="shareMap" class="full-width">Share Map</va-button>

        <va-button v-if="isSubscribable" icon="fullscreen" @click="shareViewport" class="full-width"
          >Share Current Map View</va-button
        >

        <va-button v-if="isSubscribable" icon="code" @click="copyEmbed" class="full-width"
          >Embed Current Map View</va-button
        >
      </div>

      <div v-if="isAdmin">
        <h3>Administrator Options</h3>

        <div class="button-bar">
          <va-button icon="anchor" @click="onSetOrigin" class="half-width" color="secondary"
            >Set Origin</va-button
          >

          <va-button icon="anchor" @click="onClearOrigin" class="half-width" color="warning"
            >Clear Origin</va-button
          >

        </div>

        <div class="contact"><a href="https://mapbuddy.app/contact.html" target="_blank">Contact support to make other changes</a>.</div>
      </div>
    </div>

    <div class="bottom-group">
      <div><strong>Filter Map Posts</strong></div>
      <va-select outline label="Time Range" v-model="timeRange" :options="timeOptions" max-height="200px" />
    </div>
  </va-sidebar>
</template>

<script>
import share from '@/lib/share.js';
import network from '@/network.js';

const TIME_OPTIONS = [
  {
    text: 'Forever',
    value: 'forever',
  },
  {
    text: 'Past Day',
    value: 'day',
  },
  {
    text: 'Past Week',
    value: 'week',
  },
  {
    text: 'Past Month',
    value: 'month',
  },
  {
    text: 'Past Year',
    value: 'year',
  },
];

function getTimeOption(value) {
  for (let timeOption of TIME_OPTIONS) {
    if (timeOption.value === value) {
      return timeOption;
    }
  }
}

export default {
  name: 'MapViewSidebar',

  computed: {
    visible() {
      return !!this.$store.state.triggerMenuSidebar;
    },

    isLoggedIn() {
      return !!this.$store.state.user;
    },
  },

  data() {
    return {
      timeOptions: TIME_OPTIONS,
      timeRange: getTimeOption(this.currentTimeRange),
    };
  },

  watch: {
    timeRange(timeOption, oldOption) {
      if (timeOption.value === oldOption?.value) {
        return;
      }

      this.$emit('timerange', timeOption.value);
    },
  },

  methods: {
    getViewportUrlPart() {
      const viewport = this.$store.state.lastViewport;

      if (!viewport) {
        return this.shareViewport();
      }

      const lat = viewport.lat.toFixed(5);
      const lon = viewport.lon.toFixed(5);
      const zoom = viewport.zoom;

      let partialUrl = `${this.name}/@${lat},${lon},${zoom}z`;

      // TODO: Read back value when loading map view
      if (this.timeRange.value !== 'forever') {
        partialUrl += `?r=${this.timeRange.value}`;
      }

      return partialUrl;
    },

    shareMap() {
      return this.share(`https://app.mapbuddy.app/map/${this.name}`);
    },

    shareViewport() {
      const partialUrl = this.getViewportUrlPart();

      return this.share(`https://app.mapbuddy.app/map/${partialUrl}`);
    },

    copyEmbed() {
      const partialUrl = this.getViewportUrlPart();

      let html = `<iframe src="https://app.mapbuddy.app/map-embed/${partialUrl}" width="100%" height="600" frameborder="0"></iframe>`;

      navigator.clipboard.writeText(html);

      this.$vaToast.init({
        duration: 2500,
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'dark',
        message: 'HTML code to embed your map onto a website has been copied.',
      });
    },

    share(url) {
      share(url, this.$vaToast);
    },

    subscribe() {
      this.$emit('subscribe', this.name);
    },

    unsubscribe() {
      this.close();
      this.$emit('unsubscribe', this.name);
    },

    close() {
      this.$store.commit('disableMenu');
    },

    async onSetOrigin() {
      const viewport = this.$store.state.lastViewport;

      if (!viewport) {
        throw new Error('no viewport');
      }

      const lat = viewport.lat.toFixed(5);
      const lon = viewport.lon.toFixed(5);
      const zoom = viewport.zoom;

      await network.map.update(this.name, {
        origin: {
          lat,
          lon,
          zoom,
        },
      });
    },

    async onClearOrigin() {
      await network.map.update(this.name, {
        origin: null,
      });
    },
  },

  emits: ['subscribe', 'unsubscribe', 'timerange'],

  props: {
    name: String,
    description: String,
    isMember: Boolean,
    website: String,
    isSubscribable: Boolean,
    isAdmin: Boolean,
    isVirtual: Boolean,
    memberCount: Number,
    created: String,
    currentTimeRange: String,
    icon: String,
  },
};
</script>

<style scoped>
.va-sidebar {
  overflow-y: auto;
}

.va-sidebar .padder {
  padding-bottom: 90px;
}

h1 {
  overflow: hidden;
}
h3 {
  margin: var(--gap-standard) 0;
}

.description {
  font-size: 14px;
  margin: 20px 0;
  color: var(--color-gunmetal);
}

.facts {
  color: var(--color-gunmetal);
  font-weight: bold;
  font-size: 14px;
}

.contact {
  text-align: center;
  margin-top: var(--gap-standard);
  font-size: 13px;
  font-style: italic;
}
</style>
