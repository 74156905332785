<template>
  <section class="screen simple-message">
    <div class="bullseye">This screen does not exist.</div>
  </section>
</template>

<script>
export default {
  name: 'MissingScreen',
};
</script>
