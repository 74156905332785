<template>
  <section class="screen">
    <va-modal
      v-model="displayDeletePostModal"
      message="Are you sure you would like to delete this post?"
      title="Delete Post"
      :mobile-fullscreen="false"
      ok-text="Delete"
      @ok="onPostDeleteConfirm"
    />

    <va-modal
      v-model="displayDeleteReplyModal"
      message="Are you sure you would like to delete this reply?"
      title="Delete Reply"
      :mobile-fullscreen="false"
      ok-text="Delete"
      @ok="onReplyDeleteConfirm"
    />

    <Modal
      v-if="showReportModal"
      title="Report Post"
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Report"
      @cancel="onReportCancel"
      @confirm="onReportSubmit"
    >
      <div style="margin-bottom: 10px">
        You are about to report a post by <strong>@{{ reportData.username }}</strong> in map
        <strong>#{{ reportData.map }}</strong> to an administrator. This report will be reviewed within 24 hours. Are
        you sure?
      </div>
    </Modal>

    <div v-if="loaded" class="messages-scrollable">
      <div class="map">
        <GoogleMapWrapper
          :posts="posts"
          :beacons="beacons"
          :allowZoom="true"
          :allowPan="false"
          :userPosition="lastLocation"
          :disableMessage="true"
          :noCluster="true"
          :viewportOverride="{ lat: this.post.lat, lon: this.post.lon, zoom: 14 }"
        />
      </div>

      <PostCard v-bind="post" :key="post.id" :selectable="true" />

      <div class="post-meta">
        <p v-if="post.expire">
          Disappears from map:
          {{ post.expire.toLocaleDateString() + ' ' + post.expire.toLocaleTimeString() }}
        </p>
      </div>

      <div class="padder">
        <div class="tools">
          <div class="pull-left button-bar">
            <va-button icon="visibility" v-if="!favorited" :disabled="!loaded || !isLoggedIn" @click="favorite"
              >Add Favorite</va-button
            >
            <va-button color="warning" icon="visibility_off" v-if="favorited" :disabled="!loaded" @click="unfavorite"
              >Remove Favorite</va-button
            >

            <va-button
              v-if="!mine"
              size="medium"
              icon="flag"
              color="danger"
              @click="
                onReport({
                  username: post.uname,
                  postId: post.id,
                  map: post.ch,
                })
              "
              >Report</va-button
            >

            <va-button
              v-if="mine"
              size="medium"
              icon="delete"
              color="danger"
              @click="
                onPostDelete({
                  id: post.id,
                  map: post.ch,
                })
              "
              >Delete</va-button
            >
          </div>

          <div class="pull-right">
            <va-button class="fluid-button" icon="share" @click="sharePost" />
          </div>
        </div>
      </div>

      <div v-if="replies.length" class="padder">
        <h3>Post Replies</h3>
        <div class="replies">
          <div class="reply" v-for="reply in replies" :key="reply.id">
            <div class="message selectable">{{ reply.message }}</div>

            <div class="meta">
              <a @click="$router.push(`/profile/${reply.username}`)">@{{ reply.username }}</a> &#8226;
              {{ timeDifference(new Date(reply.created)) }}
              <span v-if="reply.user_id === me">
                &#8226; <a @click="onReplyDelete(reply.id)" class="deleter">delete</a></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="repliesEnabled && isLoggedIn" class="message-input">
      <va-form tag="form" @submit.prevent="postReply">
        <div class="text">
          <va-input :disabled="sendingReply" v-model="message" placeholder="Type your reply here" :maxlength="255" />
        </div>
        <div class="button">
          <va-button type="submit" :disabled="sendingReply || !this.message.length" :block="true">Reply</va-button>
        </div>
      </va-form>
    </div>
  </section>
</template>

<script>
import network from '@/network.js';
import PostCard from '@/components/post-card.vue';
import Modal from '@/components/modal.vue';
import GoogleMapWrapper from '../components/google-map-wrapper.vue';
import timeDifference from '@/lib/time-diff.js';
import share from '@/lib/share.js';

export default {
  name: 'PostViewScreen',

  data() {
    return {
      loaded: false,
      favorited: false,
      post: null,
      replies: [],
      repliesEnabled: false,
      message: '',
      sendingReply: false,
      isLoggedIn: !!this.$store.state.user,
      mine: false,
      me: null,

      // Map Data
      posts: [],
      beacons: [],
      lastLocation: {
        // copying initial values during init
        lat: this.$store.state.lastLocation.lat,
        lon: this.$store.state.lastLocation.lon,
        acc: this.$store.state.lastLocation.acc,
        fake: this.$store.state.lastLocation.fake,
      },

      // Post Deletion Data
      displayDeletePostModal: false, // currently deleting a post
      deletePostData: null, // temporary object to contain data for delete model. TODO: sloppy

      // Report Data
      showReportModal: false,
      reportData: {
        username: null,
        map: null,
        postId: null,
      },

      // Reply Deletion Data
      displayDeleteReplyModal: false,
      deleteReplyData: null,
    };
  },

  async created() {
    await this.refresh();
  },

  methods: {
    async refresh() {
      const mapName = this.$route.params.mapName;
      const postId = this.$route.params.postId;
      this.me = this.$store.state.user;
      const blockedUsers = this.$store.state.blocked;

      this.loaded = false;

      try {
        var { post, replies, favorited } = await network.batch.postGetRepliesList(mapName, postId);
      } catch (err) {
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
        return;
      }
      this.favorited = favorited;

      this.replies = [];
      for (let reply of replies) {
        if (reply.user_id in blockedUsers) continue;
        this.replies.push(reply);
      }

      this.mine = post.uid === this.me;

      this.post = {
        type: post.level === 'beacon' ? 'beacon' : 'post',
        ...post,
        permanent: !post.expire,
        created: new Date(post.created),
        expire: post.expire && new Date(post.expire),
        metadata: post.metadata || {},
        mine: this.mine,
        levelData: this.$store.state.levels[post.level],
      };

      const mapFriendly = {
        ...this.post,
        loc: {
          lat: this.post.lat,
          lon: this.post.lon,
        },
      };

      if (post.level === 'beacon') {
        this.beacons.push(mapFriendly);
      } else {
        mapFriendly.circle = {
          strokeColor: '#000',
          strokeOpacity: 0.75,
          strokeWeight: 1,
          fillColor: '#000',
          fillOpacity: 0.1,
          center: {
            lat: this.post.lat,
            lng: this.post.lon,
          },
          radius: this.$store.state.levels[this.post.level].distance,
        };
        this.posts.push(mapFriendly);
      }

      this.repliesEnabled = 'replies' in post && typeof post.replies === 'number';

      this.loaded = true;
    },

    timeDifference(time) {
      return timeDifference(time);
    },

    async favorite() {
      await network.thread.favoritePost(this.post.ch, this.post.id);
      this.favorited = true;
    },

    async unfavorite() {
      await network.thread.unfavoritePost(this.post.ch, this.post.id);
      this.favorited = false;
    },

    async postReply() {
      this.sendingReply = true;

      try {
        await network.thread.postReply(this.post.ch, this.post.id, this.message);
        this.message = '';
        await this.refresh();
      } catch (err) {
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }

      this.sendingReply = false;
    },

    onPostDelete({ id, map }) {
      this.deletePostData = {
        id,
        map,
      };
      this.displayDeletePostModal = true;
    },

    async onPostDeleteConfirm() {
      const map = this.deletePostData.map;
      const id = this.deletePostData.id;

      try {
        await network.post.delete(map, id);
        this.$router.push('/favorites');
        // TODO:
      } catch (err) {
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }

      this.deletePostData = {};
      // this.displayDeletePostModal = false;
    },

    async onReport({ username, postId, map }) {
      this.reportData = {
        username,
        map,
        postId,
      };
      this.showReportModal = true;
    },

    onReportCancel() {
      this.showReportModal = false;
      this.reportData = {
        username: null,
        map: null,
        postId: null,
      };
    },

    async onReportSubmit() {
      const username = this.reportData.username;
      const map = this.reportData.map;
      const postId = this.reportData.postId;

      let hasReported = false;

      try {
        await network.post.report(map, postId, this.lastLocation, 'report via UI');
      } catch (err) {
        if (err.code === 'report_collision') {
          hasReported = true;
        } else {
          this.$vaToast.init({
            message: 'Unable to report this message! Please try again later.',
            position: 'bottom-right',
            offsetX: 10,
            offsetY: 58,
            color: 'warning',
          });
        }
      }

      this.showReportModal = false;

      this.reportData = {
        username: null,
        map: null,
        postId: null,
      };

      this.$vaToast.init({
        message: hasReported
          ? 'You have already reported this post.'
          : `The post by @${username} in #${map} has been reported. Consider blocking them to hide their posts.`,
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'info',
      });
    },

    onReplyDelete(replyId) {
      this.displayDeleteReplyModal = true;
      this.deleteReplyData = replyId;
    },

    sharePost() {
      share(`https://app.mapbuddy.app/post/${this.post.ch}/${this.post.id}`, this.$vaToast);
    },

    async onReplyDeleteConfirm() {
      const mapName = this.post.ch;
      const postId = this.post.id;

      try {
        await network.thread.deleteReply(mapName, postId, this.deleteReplyData);

        await this.refresh();
      } catch (err) {
        this.$vaToast.init({
          message: 'Unable to delete your reply.',
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }

      this.displayDeleteReplyModal = false;
      this.deleteReplyData = null;
    },
  },

  components: {
    Modal,
    PostCard,
    GoogleMapWrapper,
  },
};
</script>

<style scoped>
.map {
  height: 25vh;
}

.post-meta {
  font-size: 13px;
  padding: 0 var(--gap-standard);
  color: var(--color-gunmetal);
}

.replies {
  margin-top: var(--gap-standard);
  border-top: 1px solid var(--color-grey-300);
  border-bottom: 1px solid var(--color-grey-300);
}
.reply {
  margin-bottom: 10px;
  padding-top: var(--gap-standard);
}
.reply + .reply {
  border-top: 1px solid var(--color-grey-300);
}

.meta {
  font-size: 13px;
  color: var(--color-xanadu);
}

.messages-scrollable {
  padding-bottom: 57px;
}

.message-input {
  position: fixed;
  height: 57px;
  top: calc(100% - var(--nav-bar-height) - 57px);
  width: 100%;
  padding: 10px;
  background-color: var(--color-grey-300);
}

/* iOS gets a 10px bottom gap due to "home bar" */
body.platform-ios .message-input {
  top: calc(100% - var(--nav-bar-height) - 67px);
}

.message-input .text,
.message-input .button {
  display: inline-block;
}

.message-input .text {
  width: calc(100% - 100px);
}

.message-input .button {
  width: 100px;
}

.message-input .text > .va-input {
  margin-right: 10px;
}

.deleter {
  color: var(--color-red-700);
}

.tools {
  display: flex;
}
.tools .pull-left {
  text-align: left;
  flex: 6;
}
.tools .pull-right {
  text-align: right;
  flex: 1;
}
</style>
