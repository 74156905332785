<template>
  <va-app-bar v-if="visible">
    <a class="nav-link" @click="onClickHome">
      <va-icon class="material-icons" size="medium" :color="getColor('home')">public</va-icon>
    </a>
    <a class="nav-link" @click="onClickSearch">
      <va-icon class="material-icons" size="medium" :color="getColor('search')">travel_explore</va-icon>
    </a>
    <a class="nav-link" @click="onClickPost">
      <va-icon class="material-icons" size="medium" :color="getColor('post-create')">maps_ugc</va-icon>
    </a>
    <a class="nav-link" @click="onClickFavorites">
      <va-icon class="material-icons" size="medium" :color="getColor('favorites')">place</va-icon>
      <!-- mark_chat_unread , chat_bubble_outline-->
    </a>
    <a class="nav-link" @click="onClickSettings">
      <va-icon class="material-icons" size="medium" :color="getColor('settings')">settings</va-icon>
    </a>
  </va-app-bar>
</template>

<script>
export default {
  setup() {},

  computed: {
    visible() {
      return !!this.$store.state.navbar;
    },
  },

  data() {
    return {};
  },

  methods: {
    getColor(tabName) {
      if (this.$store.state.navbar === tabName) {
        return '#4aed8f'; // --color-green-500
      } else {
        return '#deece4'; // --color-grey-100
      }
    },

    onClickHome() {
      this.$router.push('/');
    },

    onClickSearch() {
      this.$router.push('/search');
    },

    onClickPost() {
      if (!this.$store.state.geo) {
        this.$vaToast.init({
          message: `You need to enable geolocation access before posting.`,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
        return;
      }

      this.$router.push('/post-create');
    },

    onClickFavorites() {
      this.$router.push('/favorites');
    },

    onClickSettings() {
      this.$router.push('/settings');
    },
  },
};
</script>

<style scoped>
.va-app-bar {
  height: var(--nav-bar-height);
  display: flex;
  overflow: hidden;
  background-color: #000 !important;
  top: calc(100% - var(--nav-bar-height));
  position: absolute;
  align-items: flex-start;
  z-index: 1;
}

/* iOS gets a 10px bottom gap due to "home bar" */
body.platform-ios .va-app-bar {
  height: calc(var(--nav-bar-height) + 10px);
  top: calc(100% - var(--nav-bar-height) - 10px);
}

.va-app-bar .nav-link {
  flex: 1;
  height: var(--nav-bar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
</style>
