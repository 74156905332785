<template>
  <div class="floaty" v-if="needGeo || needLogin">
    <va-button block icon="not_listed_location" v-if="needGeo" @click="onClickEnableGeo">
      Enable Location Access
    </va-button>

    <va-button block icon="account_circle" v-else-if="needLogin" @click="onClickLogin">
      Create Account or Login
    </va-button>
  </div>
</template>

<script>
import { getCurrentPosition } from '@/lib/location-prompt.js';

export default {
  name: 'MapOptionsCard',

  props: {
    needGeo: Boolean, // user needs to give us their geolocation
    needLogin: Boolean, // user is not logged in
  },

  methods: {
    onClickLogin() {
      this.$router.push('/account-create');
    },

    onClickEnableGeo() {
      getCurrentPosition(this.$vaToast);
    },
  },
};
</script>

<style scoped>
.floaty {
  margin: var(--gap-standard);
}
.floaty button {
  box-shadow: 0 1px 5px #00000066;
}
</style>
