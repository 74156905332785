<template>
  <section v-if="levels" class="screen">
    <div class="map">
      <GoogleMapWrapper :userPosition="userPosition" :postRadius="levels[level].distance" :allowPan="false" :allowZoom="true" />
    </div>

    <va-form tag="form" @submit.prevent="submit">
      <div class="padder">
        <div class="post-location">
          <va-input
            label="Location"
            class="text-input"
            v-model="locationSearchValue"
            placeholder="Use Current Location"
            @keyup="onLocationSearch"
            @clear="onLocationClear"
            @update:model-value="onLocationChange"
            outline
            clearable
          />
          <div v-if="locationSearchResults.length > 0" class="location-options">
            <div
              class="location-option"
              v-for="result in locationSearchResults"
              v-bind:key="result.place_id"
              @click="onLocationSelect(result.place_id)"
            >
              <div class="name">{{ result.name }}</div>
              <div class="desc">{{ result.desc }}</div>
            </div>
          </div>
        </div>

        <div class="levels">
          <va-slider
            label="Loudness"
            v-model="level"
            :min="1"
            :max="maxLoudness"
            icon-prepend="volume_down"
            icon-append="volume_up"
          />
        </div>

        <div class="post-expiration">
          <div class="map-level-circle">{{ level && levels[level].name_short }}</div>
          <div class="flavor">Expires in {{ levels[level].expire_human }} ({{ levels[level].name }})</div>
        </div>

        <div class="post-options">
          <va-input
            label="Icon"
            class="text-input icon"
            v-model="icon"
            :maxlength="24"
            outline
            @focus="clearIcon"
            @keyup="cleanIcon"
            />
          <va-select
            v-model="map"
            label="Select a Map for your Post"
            :options="maps"
            outline
            class="text-input select-map"
            max-height="144px"
          />
        </div>

        <div class="message">
          <va-input
            class="text-input"
            type="textarea"
            v-model="message"
            id="message"
            placeholder="Type a message for your post here."
            rows="4"
            :maxlength="255"
            outline
          />
        </div>

        <div class="toggle-row">
          <div>
            <va-switch
              size="small"
              v-model="enableReplies"
              true-inner-label="Replies"
              false-inner-label="No Replies"
              class="mb-1"
            />
            <span class="explanation">Allows others to reply to your post.</span>
          </div>

          <div>
            <va-switch
              size="small"
              v-model="nsfw"
              color="warning"
              true-inner-label="NSFW"
              false-inner-label="SFW"
              class="mb-1"
            />
            <span class="explanation">Enable NSFW if posting mature content.</span>
          </div>

          <div v-if="enablePermanent">
            <va-switch
              size="small"
              v-model="permanent"
              true-inner-label="Permanent"
              false-inner-label="Ephemeral"
              class="mb-1"
            />
            <span class="explanation">Permanent posts never expire.</span>
          </div>
        </div>
      </div>

      <div class="bottom-group">
        <div class="button-bar">
          <va-button type="submit" class="full-width" :disabled="message.length <= 0" icon="done"
            >Publish to Map</va-button
          >
        </div>
      </div>
    </va-form>
  </section>
  <section v-else class="screen loading">
    <va-progress-bar indeterminate color="dark" />
  </section>
</template>

<script>
import { debounce } from '@/lib/debounce.js';
import network from '@/network.js';
import GoogleMapWrapper from '../components/google-map-wrapper.vue';
import { getFirstEmoji } from '@/lib/emoji.js';

const DEFAULT_LEVEL = 3; // TODO: write to store upon change

export default {
  name: 'PostCreateScreen',

  data() {
    return {
      maxLoudness: 5,
      enablePermanent: false,

      maps: [],
      mapDict: {},
      userPosition: {
        lat: this.$store.state.lastLocation.lat,
        lon: this.$store.state.lastLocation.lon,
        acc: this.$store.state.lastLocation.acc,
      },
      levels: this.$store.state.levels,

      message: '',
      level: DEFAULT_LEVEL,
      map: this.$store.state.lastVisitedMap,
      nsfw: false,
      enableReplies: true,
      permanent: false,
      icon: '', // one single emoji

      locationSearchResults: [],
      locationSearchValue: '',
      selectedPlaceId: null,
      selectedPlaceData: null,
    };
  },

  created() {
    this.getDrawSubscriptions();

    this.$watch(
      () => this.$store.state.levels,
      (levels) => {
        this.levels = levels;
      }
    );

    this.$watch(
      () => this.$store.state.lastLocation,
      (coords) => {
        if (this.selectedPlaceData) {
          console.log('not updating user position since we have place data');
          return;
        }

        this.userPosition = coords;
      }
    );

    this.debouncedLocationSearch = debounce(async () => {
      const term = this.locationSearchValue;

      if (!term || term.length < 3) {
        this.locationSearchResults = [];
        return;
      }

      const results = await network.place.search(term, this.userPosition);

      this.locationSearchResults = results;
    }, 300);
  },

  watch: {
    map() {
      this.handleCapabilitiesOfSelectedMap();
    },
  },

  methods: {
    async submit() {
      const message = this.message;
      const level = this.level == 6 ? 'beacon' : Number(this.level);
      const nsfw = this.nsfw;
      const enableReplies = this.enableReplies;
      const permanent = this.permanent;

      try {
        const coord = this.selectedPlaceData
          ? {
              lat: this.selectedPlaceData.lat,
              lon: this.selectedPlaceData.lon,
            }
          : this.$store.state.lastLocation;

        const createdPost = await network.post.create(
          message,
          level,
          coord,
          this.map,
          nsfw,
          enableReplies,
          permanent,
          this.selectedPlaceId || undefined,
          this.icon,
        );

        this.$router.push(`/post/${createdPost.ch}/${createdPost.id}`);
      } catch (err) {
        // err.code === 'invalid_request'
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }
    },

    handleCapabilitiesOfSelectedMap() {
      const map = this.mapDict[this.map];
      const capabilities = map.capabilities;

      if (!capabilities) {
        this.maxLoudness = 5;
        this.enablePermanent = false;

        return;
      }

      this.maxLoudness = capabilities.beacon ? 6 : 5;
      if (this.level > this.maxLoudness) {
        this.level = this.maxLoudness;
      }

      this.enablePermanent = !!capabilities.permanent;
      if (!this.enablePermanent) {
        this.permanent = false;
      }

      this.icon = map.icon || '';

      // TODO: Need to disable the submit ability if the map doesn't allow the user to post
    },

    async getDrawSubscriptions() {
      // TODO: This should probably go into the global store
      // Update the value periodically or whenever join/leave a map
      const maps = await network.user.listSubscribed();
      const lastVisited = this.$store.state.lastVisitedMap;
      let isSubscribedToLastVisited = false;
      let isSubscribedToGlobal = false;

      for (let map of maps) {
        this.mapDict[map.name] = map;

        // not all maps can be posted to
        if (map.member_level >= 3) {
          // map admin
        } else if (!map.member_write) {
          // not allowed to post
          continue;
        }
        // TODO: need to consider non_member_write when posting to channels not a member of

        this.maps.push(map.name);

        if (map.name === lastVisited) {
          isSubscribedToLastVisited = true;
        } else if (map.name === 'global') {
          isSubscribedToGlobal = true;
        }
      }

      // alphabetize map list
      this.maps = this.maps.sort();

      if (isSubscribedToLastVisited) {
        this.map = lastVisited;
      } else if (isSubscribedToGlobal) {
        this.map = 'global';
      } else {
        this.map = maps[0].name;
      }

      this.handleCapabilitiesOfSelectedMap();
    },

    onLocationChange() {
      if (this.locationSearchValue === '') {
        // TODO: The @clear event isn't firing, so doing this as a hack
        this.onLocationClear();
      }
    },

    onLocationClear() {
      this.locationSearchResults = [];
      this.selectedPlaceId = null;
      this.selectedPlaceData = null;

      this.userPosition = {
        lat: this.$store.state.lastLocation.lat,
        lon: this.$store.state.lastLocation.lon,
        acc: this.$store.state.lastLocation.acc,
      };
    },

    async onLocationSearch() {
      await this.debouncedLocationSearch();
    },

    async onLocationSelect(placeId) {
      this.selectedPlaceId = placeId;

      let foundPlaceName = '';
      for (let entry of this.locationSearchResults) {
        if (entry.place_id === placeId) {
          foundPlaceName = entry.name;
        }
      }

      this.locationSearchValue = foundPlaceName;
      this.locationSearchResults = [];
      this.getSelectedPlaceData();
    },

    async getSelectedPlaceData() {
      const placeId = this.selectedPlaceId;

      if (!placeId) {
        throw new Error('no place id');
      }

      const data = await network.place.lookup(placeId);

      this.selectedPlaceData = data;

      this.message = data.name + '\n' + this.message;

      this.userPosition = {
        lat: data.lat,
        lon: data.lon,
        acc: 0,
      };

      // TODO: Pass through metadata: phone, website, address
      // Though it would be weird to invisibly pass them along
      // Probably we'll need inputs for that data, if applicable, and pre-populate
    },

    // use only the first encountered emoji
    cleanIcon() {
      this.icon = getFirstEmoji(this.icon);
    },

    clearIcon() {
      this.icon = '';
    },
  },
  components: {
    GoogleMapWrapper,
  },
};
</script>

<style scoped>
.map {
  height: 180px;
}

.loading {
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}

.toggle-row {
  margin-top: 10px;
}
.toggle-row .explanation {
  font-size: 12px;
  color: var(--color-xanadu);
}

.post-options {
  margin-bottom: var(--gap-standard);
  display: flex;
}
.post-options .icon {
  width: 100px;
  margin-right: var(--gap-standard);
}
.post-options .select-map {
  width: calc(100% - 100px - var(--gap-standard));
}

.post-expiration {
  margin-bottom: var(--gap-standard);
}
.post-expiration > div {
  vertical-align: middle;
}

.post-expiration .flavor {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  color: var(--color-gunmetal);
}

.message {
  margin-top: 10px;
}

.message textarea {
  width: 100%;
}

.levels {
  margin: 10px 0;
}

.post-location {
  margin: 0 0 10px 0;
  padding: 0;
}
.post-location .location-options {
  background-color: white;
  border: 1px solid var(--color-grey-300);
  border-top: 0;
  margin-bottom: 10px;
}
.post-location .location-option {
  border-bottom: 1px solid var(--color-grey-300);
  padding: 5px;
}
.post-location .location-option:last-of-type {
  border-bottom: none;
}
.post-location .location-option .name {
  font-size: 13px;
  font-weight: bold;
  color: var(--color-green-pigment);
}
.post-location .location-option .desc {
  font-size: 11px;
  color: var(--color-gunmetal);
}
/* TODO: make this global, test everywhere, support dynamic height */
.bottom-group {
  position: fixed;
  height: calc(56px + 2px); /* making too tall to prevent rounding error 1px gap below bar */
  top: calc(100% - var(--nav-bar-height) - 56px);
  box-sizing: border-box;
}
body.platform-ios .bottom-group {
  top: calc(100% - var(--nav-bar-height) - 56px - 10px);
}
.va-form {
  padding-bottom: 56px;
}
</style>
