<template>
  <section class="screen simple-message">
    <div class="bullseye">Authorizing...</div>
  </section>
</template>

<script>
import { authenticateAppleIdentity } from '@/lib/authenticate.js';

export default {
  name: 'AccountCreateAppleScreen', // Really, more of an Authorize screen...

  async created() {
    const code = this.$route.query.code;

    try {
      await authenticateAppleIdentity(code, this.$router);
    } catch (err) {
      // TODO: This should redirect to account create screen instead of staying here
      this.$vaToast.init({
        message: err.message,
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'warning',
      });
    }
  },
};
</script>
