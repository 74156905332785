<template>
  <section class="screen">
    <Modal
      v-if="showLeaveModal"
      title="Unsubscribe from Map"
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Leave"
      @cancel="onUnsubscribeCancel"
      @confirm="onUnsubscribeSubmit"
    >
      <div style="margin-bottom: 10px">
        You are about to unsubscribe from map <strong>#{{ leaveMapName }}</strong
        >. Are you sure?
      </div>
    </Modal>

    <Modal
      v-if="showPasswordModal"
      title="Join Map"
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Join"
      @cancel="onMapPasswordCancel"
      @confirm="onMapPasswordSubmit"
    >
      <va-form tag="form" @submit.prevent="onMapPasswordSubmit">
        <div class="mb-1">
          <strong>The map #{{ mapData.name }}</strong> requires a password to join.
        </div>
        <va-input label="Map Password" v-model="mapPassword" autocorrect="off" autocapitalize="none" />
      </va-form>
    </Modal>

    <MapViewSidebar
      :name="mapData.name"
      :description="mapData.description"
      :isMember="mapData.is_member"
      :isSubscribable="!mapData.virtual"
      :isAdmin="mapData.is_admin"
      :website="mapData.website"
      :memberCount="mapData.member_count"
      :created="mapData.created"
      :isVirtual="mapData.virtual"
      :currentTimeRange="timeRange"
      :icon="mapData.icon"
      @subscribe="onSubscribe"
      @unsubscribe="onUnsubscribe"
      @timerange="onTimeRange"
    />

    <div id="map">
      <div v-if="isEmbedded" class="powered-by">
        <a href="https://mapbuddy.app" target="_blank">Powered by <span>Map Buddy</span></a>
      </div>

      <div class="floating-post-card">
        <MapOptionsCard v-if="!isEmbedded" :needGeo="!$store.state.geo" :needLogin="!$store.state.user" />

        <PostCard
          v-if="selectedPost"
          v-bind="selectedPost"
          :selectable="isEmbedded"
          :isEmbedded="isEmbedded"
        />
      </div>

      <div id="map-container">
        <GoogleMapWrapper
          :userPosition="lastLocation"
          v-bind:posts="posts"
          v-bind:beacons="beacons"
          v-bind:mapDataHasLoaded="!!mapData.name"
          v-bind:origin="origin"
          v-bind:viewportOverride="viewport"
          v-bind:noCluster="noCluster"
          @bounds="onBounds"
          @focusMarker="onFocusMarker"
          @click="onMapClick"
        />
      </div>
    </div>
    <div v-if="isEmbedded" id="posts">
      <div v-if="posts.length > 0 || beacons.length > 0">
        <PostCard
          v-for="post in posts"
          v-bind="post"
          :ref="'post-' + post.id"
          :key="post.id"
          :selectable="true"
          :isEmbedded="true"
        />
        <PostCard
          v-for="beacon in beacons"
          v-bind="beacon"
          :ref="'post-' + beacon.id"
          :key="beacon.id"
          :selectable="true"
          :isEmbedded="true"
        />
      </div>
      <div v-else class="no-posts">
        There are no posts at this location.<br />
        Try zooming or panning around to find some!
      </div>
    </div>
  </section>
</template>

<script>
import GoogleMapWrapper from '@/components/google-map-wrapper.vue';
import PostCard from '@/components/post-card.vue';
import MapOptionsCard from '@/components/map-options-card.vue';
import Modal from '@/components/modal.vue';
import MapViewSidebar from '@/components/map-view-sidebar.vue';

import network from '@/network.js';
import { distance } from '@/lib/geo.js';

// regular posts
const POST_DISTANCE_THRESHOLD = 2; // Number of meters to travel before triggering a distance-based fetch
const TIME_THRESHOLD = 10 * 1000; // Number of ms to wait between scheduled fetches

// beacons
const BEACON_DISTANCE_THRESHOLD = 10; // Number of meters to travel before triggering a distance-based fetch

const MAX_SIDE = 200_000; // maximum width/height of map in meters when querying beacons

export default {
  name: 'MapViewScreen',
  data() {
    return {
      mapData: {},
      invalidMap: false,

      isEmbedded: !!this.$route.meta.embedded,

      leaveMapName: '',
      showLeaveModal: false,

      allPostsAndBeacons: new Map(), // post_id => data
      posts: [], // list of posts currently visible to user
      activePosts: new Map(), // TODO: Why is there posts and activePosts?
      beacons: [], // list of beacon posts currently visible to user

      levels: this.$store.state.levels,

      origin: null,

      timerId: null, // used for clearing geolocation interval
      lastFetch: 0,
      lastLocation: {
        // copying initial values during init
        lat: this.$store.state.lastLocation.lat,
        lon: this.$store.state.lastLocation.lon,
        acc: this.$store.state.lastLocation.acc,
        fake: this.$store.state.lastLocation.fake,
      },

      lastBounds: {
        center: {
          lat: null,
          lon: null,
        },
        width: 0,
        height: 0,
        zoom: null,
      },

      mapPassword: '',
      showPasswordModal: false,

      noCluster: false,

      timeRange: this.$route.query.r || 'forever',

      selectedPost: null,
    };
  },

  computed: {
    map() {
      // TODO: When leaving screens it'll trigger this map change
      // This causes some code on this screen to run after switching to another screen
      // This results in an additional network request and title change
      return this.$route.params.mapName;
    },

    viewport() {
      let viewport = this.$route.params.viewport;

      if (!viewport) {
        return null;
      }

      if (viewport.charAt(0) !== '@') {
        return null;
      }

      viewport = viewport.substr(1);

      let [latitude, longitude, zoom] = viewport.split(',');

      return {
        lat: Number(latitude),
        lon: Number(longitude),
        zoom: Number(zoom.slice(0, -1)), // final char is "z" meaning Zoom
      };
    },
  },

  async created() {
    this.$watch(
      () => this.$store.state.lastLocation,
      (coords) => {
        this.onNudge(coords);
      }
    );

    // Switching between maps doesn't run the creation lifecycle functions again
    this.$watch(
      () => this.map,
      (currentMap) => {
        // TODO: There's a bug when switching away
        // Currently checking isMapView as a hacky solution
        if (this.$route.meta.isMapView && currentMap) {
          this.onMapSwitch(currentMap);
        }
      }
    );

    this.$watch(
      () => this.$store.state.levels,
      (levels) => {
        this.levels = levels;
      }
    );

    this.timerId = setInterval(() => {
      this.onNudge();
    }, 5000);

    this.onMapSwitch();

    this.$watch(
      () => this.$store.state.appVisible,
      (newState, oldState) => {
        if (newState && !oldState) {
          console.log('app regained focus, refetching posts');
          this.fetchAndDrawPosts();
        }
        // TODO: clear timerId in an else, restart in if
      }
    );
  },

  unmounted() {
    clearInterval(this.timerId);
  },

  methods: {
    async onMapSwitch() {
      try {
        this.lastFetch = Date.now();
        const { map, posts } = await network.batch.mapGetPostsList(this.map, this.lastLocation, this.timeRange);
        this.setMapData(map);
        this.handleIncomingPosts(posts.posts);
      } catch (err) {
        await this.handleInvalidMap(err);
      }

      this.$store.commit('setVisitedMap', this.map);
    },

    removePost(id) {
      const posts = this.posts;

      // TODO: Using a loop is slow
      for (let i = 0; i < posts.length; i++) {
        const post = posts[i];
        if (post.id === id) {
          posts.splice(i, 1);
          return i;
        }
      }

      return -1;
    },

    handleIncomingPosts(posts) {
      const visibleIds = new Set(posts.map((post) => post.id));

      // Removals
      this.activePosts.forEach((_post, postId) => {
        // Already know about this post and it still exists
        if (visibleIds.has(postId)) {
          return;
        }

        this.removePost(postId);
        this.activePosts.delete(postId);
      });

      // Additions
      posts.forEach((post) => {
        if (this.activePosts.has(post.id)) {
          // Already know about this post
          return;
        }

        this.addPost(post);
      });
    },

    addPost(post) {
      const me = this.$store.state.user;

      if (this.filterPost(post)) return;

      // TODO: The map should determine the shape of this object, not the current screen
      const obj = {
        type: 'post',
        ...post,
        permanent: !post.expire,
        created: new Date(post.created),
        metadata: post.metadata || {},
        color: '#000',
        mine: post.uid === me,
        loc: {
          lat: post.lat,
          lon: post.lon,
        },
        levelData: this.levels[post.level],
        circle: {
          strokeColor: '#000',
          strokeOpacity: 0.75,
          strokeWeight: 1,
          fillColor: '#000',
          fillOpacity: 0.1,
          center: {
            lat: post.lat,
            lng: post.lon,
          },
          radius: this.levels[post.level].distance,
        },
      };
      this.posts.push(obj);

      this.allPostsAndBeacons.set(post.id, obj);


      this.activePosts.set(post.id, {
        data: post,
      });
    },

    onNudge(coordinate) {
      const now = Date.now();

      if (coordinate) {
        // coordinate is provided when geolocation poll fired
        const priorLocation = this.lastLocation;

        this.lastLocation = {
          lat: coordinate.lat,
          lon: coordinate.lon,
          acc: coordinate.acc,
        };

        if (distance(priorLocation, coordinate) >= POST_DISTANCE_THRESHOLD) {
          return this.fetchAndDrawPosts();
        }
      }

      if (this.lastFetch < now - TIME_THRESHOLD) {
        return this.fetchAndDrawPosts();
      }
    },

    async onSubscribe(mapName) {
      console.log('SUBSCRIBE', mapName);
      await network.membership.subscribe(mapName);
      await this.getMapData();
      this.$vaToast.init({
        message: `You are now subscribed to #${mapName}! Posts on this map will appear on your home screen.`,
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'info',
      });
    },

    onUnsubscribe(mapName) {
      console.log('UNSUBSCRIBE', mapName);
      this.leaveMapName = mapName;
      this.showLeaveModal = true;
    },

    onUnsubscribeCancel() {
      this.leaveMapName = '';
      this.showLeaveModal = false;
    },

    async onUnsubscribeSubmit() {
      await network.membership.unsubscribe(this.leaveMapName);

      await this.getMapData();
      this.showLeaveModal = false;
    },

    onTimeRange(timeRange) {
      this.timeRange = timeRange;
      this.fetchAndDrawPosts();
      this.fetchAndDrawBounds();
    },

    fetchAndDrawBounds() {
      this.onBounds(this.lastBounds, true);
    },

    setBounds(center, width, height, zoom) {
      this.lastBounds = {
        center,
        width,
        height,
        zoom
      };
    },

    async onBounds({ center, width, height, zoom }, force) {
      if (!force && zoom === this.lastBounds.zoom && distance(this.lastBounds.center, center) < BEACON_DISTANCE_THRESHOLD) {
        console.log('viewport did not move enough to load beacons');
        return;
      }

      const reportedWidth = Math.min(width, MAX_SIDE);
      const reportedHeight = Math.min(height, MAX_SIDE);
      this.setBounds(center, reportedWidth, reportedHeight, zoom);
      const beacons = await network.post.listBeacon(this.map, center, reportedWidth, reportedHeight, this.timeRange);
      this.beacons = [];
      const me = this.$store.state.user;

      for (let beacon of beacons.posts) {
        if (this.filterPost(beacon)) continue;

        const obj = {
          ...beacon,
          type: 'beacon',
          color: '#000',
          permanent: !beacon.expire,
          level: 6,
          created: new Date(beacon.created),
          metadata: beacon.metadata || {},
          mine: beacon.uid === me,
          levelData: this.levels[beacon.level],
          loc: {
            lat: beacon.lat,
            lon: beacon.lon,
          },
        };
        this.beacons.push(obj);

        this.allPostsAndBeacons.set(beacon.id, obj);
      }
    },

    async getMapData() {
      try {
        const map = await network.map.get(this.map);
        this.setMapData(map);
      } catch (err) {
        await this.handleInvalidMap(err);
      }
    },

    setMapData(mapData) {
      this.mapData = mapData;
      this.origin = mapData.origin;
      this.invalidMap = false;
      this.showPasswordModal = mapData.has_password && !mapData.is_member; // TODO: If not logged in when viewing password map, redirect to / and toast an error
      this.noCluster = !!mapData?.capabilities?.nocluster;

      if (mapData.name === 'home') {
        this.$store.commit('setTitle', { title: 'Your Subscribed Maps' });
      } else if (mapData.name === 'suggested') {
        this.$store.commit('setTitle', { title: 'Suggested Maps' });
      } else {
        this.$store.commit('setTitle', { title: 'Map', subtitle: `#${mapData.name}` });
      }
    },

    handleInvalidMap(err) {
      this.clearPosts();
      this.mapData = {};
      this.invalidMap = true;
      this.origin = null;
      this.$store.commit('setTitle', { title: 'Map', subtitle: 'INVALID MAP' });

      if (err.code !== 'map_not_found') {
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }

      throw err;
    },

    clearPosts() {
      this.activePosts.clear();
      this.posts = [];
      this.beacons = [];
    },

    async fetchAndDrawPosts() {
      this.lastFetch = Date.now();
      try {
        const posts = await network.post.list(this.map, this.lastLocation, this.timeRange);
        if (!posts.posts) {
          // TODO: Display error
          console.error('error getting posts');
          return;
        }
        this.handleIncomingPosts(posts.posts);
      } catch (err) {
        // TODO: Display error
        console.error(err);
      }
    },

    onMapPasswordCancel() {
      this.$router.push('/');
    },

    // on mobile, onMapClick is called right before onFocusMarker, which is mostly OK
    // on desktop, thanks to @click.stop, onMapClick is no longer called
    onFocusMarker({ id, channel }) {
      void channel;

      const data = this.allPostsAndBeacons.get(id);

      if (!data) {
        console.warn(`unable to lookup post with id ${id}`);
      }

      if (this.isEmbedded) {
        const postElement = this.$refs[`post-${id}`]?.[0]?.$el;
        if (!postElement) {
          console.warn('Post element is no longer available! Unable to scroll.');
          return;
        }
        postElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } else {
        this.selectedPost = data || null;
      }
    },

    // If you click the map, clear the selected post
    onMapClick() {
      console.log('deselect');
      this.selectedPost = null;
    },

    async onMapPasswordSubmit() {
      try {
        await network.membership.subscribe(this.mapData.name, this.mapPassword);
        await this.getMapData();
      } catch (err) {
        this.mapPassword = '';
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
        return;
      }

      this.showPasswordModal = false;
      this.mapPassword = '';
    },

    filterPost(post) {
      const blockedUsers = this.$store.state.blocked;
      const doFilterNsfw = !this.$store.state.nsfw;

      if (post.uid in blockedUsers) {
        return true;
      } else if (doFilterNsfw && post.nsfw) {
        return true;
      }

      return false;
    },
  },

  components: {
    PostCard,
    GoogleMapWrapper,
    MapOptionsCard,
    Modal,
    MapViewSidebar,
  },
};
</script>

<style scoped>
section.screen {
  display: flex;
  flex-direction: column;
}
#map {
  flex: 1;
  position: relative;
}

#map-container {
  height: 100%;
  width: 100%;
}

#posts {
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 600px) and (orientation: landscape) {
  section.screen {
    flex-direction: row;
  }

  #posts {
    max-width: 500px;
  }
}

.tools {
  position: absolute;
}
.tools-tr {
  top: 10px;
  right: 10px;
}
.tools-br {
  bottom: 10px;
  right: 10px;
}
.tools-bl {
  bottom: 10px;
  left: 10px;
}

.map-name {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  pointer-events: none;
}
.map-name > div {
  background-color: #000000;
  color: #ffffff;
  display: inline-block;
  padding: 0 6px 2px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 0px 10px #00000033;
}
.map-name.invalid > div {
  background-color: var(--color-red-700);
}

.floating-post-card {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
}

.powered-by {
  position: absolute;
  bottom: 24px;
  right: 60px;
  z-index: 2;
  font-size: 11px;
  text-align: center;
  line-height: 14px;
  background-color: white;
  padding: 5px;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}
.powered-by,
.powered-by a {
  color: var(--color-gunmetal);
  text-decoration: none;
}
.powered-by span {
  font-size: 14px;
  font-weight: bold;
  display: block;
}
.no-posts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  color: var(--color-grey-500);
  text-align: center;
  line-height: 2em;
}
</style>
