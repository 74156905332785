<template>
  <section class="screen">
    <va-form tag="form" @submit.prevent="submit">
      <div class="padder">
        <va-input
          class="text-input mb-1"
          v-model="mapName"
          label="Map Name"
          :rules="[
            (value) => value.length >= 3 || `Should be at least 3 characters`,
            (value) => value.length <= 24 || `Should be at most 24 characters`,
            (value) => /^[0-9a-z-]+$/.test(value) || `Only lowercase letters, numbers, and hyphens allowed`,
          ]"
          :error-messages="mapNameInputErrorMessage"
          :error="!!mapNameInputErrorMessage"
          :messages="['Only lowercase letters, numbers, and hyphens allowed']"
          @keyup="cleanMapName"
          autocapitalize="off"
          :maxlength="24"
          outline
        />

        <va-input
          type="textarea"
          class="text-input mb-1"
          v-model="description"
          placeholder="The description aids with searching and is visible in the sidebar."
          label="Map Description"
          :rules="[
            (value) => value.length > 0 || `Field is required`,
            (value) => value.length <= 400 || `Max length is 400 characters`,
          ]"
          rows="3"
          :maxlength="400"
          outline
        />

        <va-input
          label="Type an Emoji Icon (Optional)"
          class="text-input mb-1 icon"
          v-model="icon"
          :maxlength="24"
          outline
          @keyup="cleanIcon"
          />

        <va-input
          style="margin-bottom: 10px"
          class="text-input mb-1"
          v-model="password"
          label="Map Password (Optional, needed to join)"
          :rules="[(value) => value.length <= 32 || `Should be at most 32 characters`]"
          :maxlength="32"
          autocorrect="off"
          autocapitalize="none"
          outline
        />

        <div class="mb-1">
          <va-switch v-model="listable" true-label="Publicly discoverable map" false-label="Private map" />
        </div>

        <div class="mb-1">
          <va-switch v-model="anyone" true-label="Anyone can post" false-label="Only admins can post" />
        </div>

        <div class="mb-1">
          <va-switch
            v-model="cluster"
            true-label="Nearby Posts are Grouped"
            false-label="Nearby Posts are not Grouped"
          />
        </div>

        <hr />

        <div>
          <strong>Advanced Channel Capabilities</strong>
        </div>

        <div class="mb-1">
          <p>
            <small
              ><em
                >These will eventually be paid features, but for now, maps created with them will be grandfathered in
                for free.</em
              ></small
            >
          </p>
        </div>

        <div class="mb-1">
          <va-switch v-model="permanent" true-label="Permanent Posts Enabled" false-label="Permanent Posts Disabled" />
        </div>

        <div class="mb-1">
          <va-switch v-model="beacon" true-label="Beacon Posts Enabled" false-label="Beacon Posts Disabled" />
        </div>

        <!--
        <div class="mb-1">
          <va-switch disabled true-label="Driving Directions Enabled" false-label="Driving Directions Disabled" />
        </div>

        <div class="mb-1">
          <va-switch disabled true-label="Image Uplaods Enabled" false-label="Image Uplaods Disabled" />
        </div>

        <div class="mb-1">
          <va-switch disabled true-label="Social Media Links Enabled" false-label="Social Media Links Disabled" />
        </div>

        <div class="mb-1">
          <va-switch disabled true-label="Website Link Enabled" false-label="Website Link Disabled" />
        </div>

        <div class="mb-1">
          <va-switch disabled true-label="Check-Ins Enabled" false-label="Check-Ins Disabled" />
        </div>

        <div class="mb-1">
          <va-switch disabled true-label="Additional Description Enabled" false-label="Additional Description Disabled" />
        </div>

        <div class="mb-1">
          <va-switch disabled true-label="Email / Phone Enabled" false-label="Email / Phone Disabled" />
        </div>
        -->

        <div class="bottom-group">
          <div class="button-bar">
            <va-button class="half-width" @click="cancel" icon="cancel" outline color="gray">Cancel</va-button>
            <va-button
              class="half-width"
              type="submit"
              icon="done"
              v-bind:disabled="!mapName.length || !description.length || sendingRequest"
              >Create Map</va-button
            >
          </div>
        </div>
      </div>
    </va-form>
  </section>
</template>

<script>
import network from '@/network.js';
import { getFirstEmoji } from '@/lib/emoji.js';

export default {
  name: 'MapCreateScreen',
  data() {
    return {
      mapName: '',
      description: '',
      listable: true,
      password: '',
      anyone: true,
      icon: '',

      // capabilities
      permanent: false,
      beacon: false,
      cluster: true,

      sendingRequest: false,
      mapNameInputErrorMessage: '',
    };
  },

  methods: {
    async submit() {
      if (!this.mapName) {
        this.$vaToast.init({
          message: 'You forgot to provide a map name!',
          position: 'bottom-right',
          color: 'warning',
        });
        return;
      } else if (!this.description) {
        this.$vaToast.init({
          message: 'Please provide a description!',
          position: 'bottom-right',
          color: 'warning',
        });
        return;
      }

      this.sendingRequest = true;

      try {
        const result = await network.map.create(
          this.mapName,
          this.listable,
          this.password || null,
          !this.anyone,
          this.description,
          this.icon,
          {
            permanent: this.permanent,
            beacon: this.beacon,
            nocluster: !this.cluster,
          }
        );

        this.$vaToast.init({
          message: `Map ${result.name} created!`,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'primary',
        });

        this.$router.push(`/map/${result.name}`);
      } catch (err) {
        if (err.code === 'map_collision') {
          this.mapNameInputErrorMessage = err.message;
        } else {
          this.$vaToast.init({
            message: err.message,
            position: 'bottom-right',
            color: 'warning',
          });
        }
        console.error(err);
      }

      this.sendingRequest = false;
    },

    // TODO: in order to retain cursor position, we'll some how need to grab the underlying event
    // https://stackoverflow.com/a/35400601/538646
    cleanMapName() {
      if (this.mapName !== this.mapName.toLowerCase()) {
        this.mapName = this.mapName.toLowerCase();
      }

      if (this.mapName.includes(' ')) {
        this.mapName = this.mapName.replaceAll(' ', '-');
      }

      if (this.mapName.match(/[^0-9a-z-]/)) {
        this.mapName = this.mapName.replaceAll(/[^0-9a-z-]/g, '');
      }
    },

    cleanIcon() {
      this.icon = getFirstEmoji(this.icon);
    },

    cancel() {
      this.$router.push('/search');
    },
  },
};
</script>

<style scoped>
.form-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-blue-700);
}
</style>
