<template>
  <section class="screen">
    <Modal
      v-if="showAccountDeletionModal"
      :title="'Delete @' + username"
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Delete Account"
      confirmButtonColor="danger"
      @cancel="onDeleteAccountCancel"
      @confirm="onDeleteAccountSubmit"
    >
      <va-form tag="form" @submit.prevent="onDeleteAccountSubmit">
        <div class="mb-1">
          This will permanently and immediately <strong>delete your user account</strong>, including
          all of your posts, replies, favorites, map subscriptions, check-ins,
          and your username will become available for others to register.
          Any maps you've created will not be deleted.
          Type <strong>DELETE</strong> to confirm.
        </div>
        <va-input label='Type "DELETE" To Confirm' v-model="accountDeleteCheck" autocorrect="off" autocapitalize="none" />
      </va-form>
    </Modal>

    <div class="settings-wrap">
      <div class="settings-section">
        <h3>Map Settings</h3>

        <div class="settings-row">
          <div class="settings-label">
            Post Radius Circles
          </div>
          <div class="settings-action">
            <va-switch
              v-model="debug"
              color="secondary"
            />
          </div>
        </div>

        <div class="settings-row">
          <div class="settings-label">
            Show NSFW Posts
          </div>
          <div class="settings-action">
            <va-switch
              v-model="nsfw"
              color="secondary"
            />
          </div>
        </div>
      </div>

      <div class="settings-section">
        <h3>Account Settings</h3>

        <div v-if="noGeo" class="settings-row" @click="enableGeo">
          <div class="settings-label">
            Enable Location Access
          </div>
          <div class="settings-action">
            <va-icon name="chevron_right" color="gray" />
          </div>
        </div>

        <div class="settings-row" @click="blocked">
          <div class="settings-label">
            Manage Blocked Users
          </div>
          <div class="settings-action">
            <va-icon name="chevron_right" color="gray" />
          </div>
        </div>

        <div v-if="!anonymous" class="settings-row" @click="onClickProfile">
          <div class="settings-label">
            View Your Profile
          </div>
          <div class="settings-action">
            <va-icon name="chevron_right" color="gray" />
          </div>
        </div>

        <div class="settings-row" @click="logout">
          <div v-if="anonymous" class="settings-label">
            Reset App Data
          </div>
          <div v-else class="settings-label">
            Logout from App
          </div>
          <div class="settings-action">
            <va-icon name="chevron_right" color="gray" />
          </div>
        </div>

        <div v-if="!anonymous" class="settings-row" @click="onShowDeleteAccountModal">
          <div class="settings-label">
            Permanently Delete Account
          </div>
          <div class="settings-action">
            <va-icon name="delete_forever" color="gray" />
          </div>
        </div>
      </div>

      <div class="settings-section">
        <h3>About Map Buddy</h3>

        <div class="settings-row" @click="tutorial">
          <div class="settings-label">
            Launch Tutorial
          </div>
          <div class="settings-action">
            <va-icon name="chevron_right" color="gray" />
          </div>
        </div>

        <a class="settings-row" target="_blank" href="https://mapbuddy.app/terms-of-service.html?embed">
          <div class="settings-label">
            Terms of Service
          </div>
          <div class="settings-action">
            <va-icon name="open_in_new" color="gray" />
          </div>
        </a>

        <a class="settings-row" target="_blank" href="https://mapbuddy.app/privacy-policy.html?embed">
          <div class="settings-label">
            Privacy Policy
          </div>
          <div class="settings-action">
            <va-icon name="open_in_new" color="gray" />
          </div>
        </a>

        <a v-if="!installed && platform === 'android' || platform === 'unknown'" class="settings-row" target="_blank" href="https://play.google.com/store/apps/details?id=chat.radar.app.twa">
          <div class="settings-label">
            Install from Play Store
          </div>
          <div class="settings-action">
            <va-icon name="open_in_new" color="gray" />
          </div>
        </a>

        <a v-if="!installed && platform === 'ios' || platform === 'unknown'" class="settings-row" target="_blank" href="https://apps.apple.com/app/radar-chat-local-discovery/id1599975217">
          <div class="settings-label">
            Install from App Store
          </div>
          <div class="settings-action">
            <va-icon name="open_in_new" color="gray" />
          </div>
        </a>

      </div>
      <div class="copyright">Map Buddy &copy; 2023 Thomas Hunter II</div>
    </div>

    <div v-if="anonymous" class="bottom-group">
      <div class="button-bar">
        <va-button icon="account_circle" class="full-width" @click="login"
          >Create Account or Login</va-button
        >
      </div>
    </div>
  </section>
</template>

<script>
import { ROOT_URL, INSTALLED, PLATFORM } from '@/lib/platform.js';
import { getCurrentPosition } from '@/lib/location-prompt.js';
import Modal from '@/components/modal.vue';
import network from '@/network.js';

const DELETE_CONFIRM = 'DELETE';

export default {
  name: 'SettingsScreen',

  async created() {
    // await network.user.getSelf()
    if (!this.anonymous) {
      this.$store.commit('setTitle', { title: 'Settings', subtitle: `@${this.username}` });
    }
  },

  data() {
    return {
      beta: this.$store.state.beta,
      debug: this.$store.state.debug,
      nsfw: this.$store.state.nsfw,
      username: this.$store.state.username || 'anonymous',
      anonymous: !this.$store.state.user,

      installed: INSTALLED,
      platform: PLATFORM,

      showAccountDeletionModal: false,
      accountDeleteCheck: '',
    };
  },

  computed: {
    noGeo() {
      return !this.$store.state.geo;
    },
  },

  watch: {
    beta() {
      this.$store.commit('setBeta', this.beta);
    },

    debug() {
      this.$store.commit('setDebug', this.debug);
    },

    nsfw() {
      this.$store.commit('setNsfw', this.nsfw);
    },
  },

  methods: {
    logout() {
      if (this.anonymous) {
        this.wipeStoreAndGoHome();
        return;
      }

      this.$store.state.loginExpired = true;
    },

    wipeStoreAndGoHome() {
      this.$store.dispatch('wipe');
      location.href = ROOT_URL;
    },

    enableGeo() {
      getCurrentPosition(this.$vaToast);
    },

    tutorial() {
      this.$router.push('/tutorial');
    },

    login() {
      this.$router.push('/account-create');
    },

    blocked() {
      this.$router.push('/blocked-users');
    },

    onClickProfile() {
      this.$router.push(`/profile/${this.username}`);
    },

    onShowDeleteAccountModal() {
      this.showAccountDeletionModal = true;
    },

    onDeleteAccountCancel() {
      this.showAccountDeletionModal = false;
      this.accountDeleteCheck = '';
    },

    async onDeleteAccountSubmit() {
      if (this.accountDeleteCheck !== DELETE_CONFIRM) {
        this.$vaToast.init({
          duration: 2500,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'danger',
          message: `You need to type exactly "${DELETE_CONFIRM}" to delete your account.`,
        });
        return;
      }
      
      try {
        await network.user.deleteSelfAccount();
      } catch (err) {
        console.error(err);
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
        return;
      }

      this.wipeStoreAndGoHome();
    },
  },

  components: {
    Modal,
  },
};
</script>

<style scoped>
section.screen {
  background-color: var(--color-grey-300);
}
h3 {
  color: #000;
  margin: 0 0 10px 0;
  padding-left: 10px;
  padding-top: 10px;
  text-align: left;
  font-size: 16px;
}
.settings-wrap {
  padding-bottom: 66px;
}
.settings-section {
  margin-bottom: 10px;
  color: var(--color-gunmetal);
}
a.settings-row {
  text-decoration: none;
}
.settings-row:first-child {
  border-top: 1px solid var(--color-grey-100);
}
.settings-row {
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 48px;
  border-bottom: 1px solid var(--color-grey-100);
}
.settings-label {
  flex: 5;
  line-height: 48px;
  padding-left: 10px;
}
.settings-action {
  flex: 1;
  line-height: 36px;
  text-align: right;
  padding-right: 10px;
}
.settings-row .va-icon {
  padding-top: 8px;
}
.copyright {
  text-align: center;
  font-size: 13px;
  color: var(--color-xanadu);
}
/* TODO: make this global, test everywhere, support dynamic height */
.bottom-group {
  position: fixed;
  height: calc(56px + 2px); /* making too tall to prevent rounding error 1px gap below bar */
  top: calc(100% - var(--nav-bar-height) - 56px);
  box-sizing: border-box;
}
body.platform-ios .bottom-group {
  top: calc(100% - var(--nav-bar-height) - 56px - 10px);
}
</style>
