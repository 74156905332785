import network from '@/network.js';
import store from '@/store.js';
import afterLogin from '@/lib/after-login.js';

export async function authenticateAuthCode(code, router) {
  const response = await network.auth.confirmEmailToken(code, store.state.lastLocation);

  return await storeValuesAndRedirect(response, router);
}

export async function authenticateGoogleIdentity(token, router) {
  const response = await network.auth.googleIdentity(token, store.state.lastLocation);

  storeValuesAndRedirect(response, router);
}

export async function authenticateAppleIdentity(code, router) {
  const response = await network.auth.appleIdentity(code, store.state.lastLocation);

  storeValuesAndRedirect(response, router);
}

async function storeValuesAndRedirect({ header, promptUsername, userId, username }, router) {
  store.commit('setUser', userId);
  store.commit('setAuth', header);

  if (promptUsername) {
    router.push('/username');
    return;
  } else {
    store.commit('setUsername', username);
    await afterLogin(router);
  }
}
