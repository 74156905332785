<template>
  <section class="screen">
    <div class="padder">
      <p>Your username is displayed if you create a post or if you reply to a post.</p>
    </div>

    <div class="bottom-group">
      <div>
        <va-form tag="form" @submit.prevent="submitUsername">
          <va-input
            class="text-input mb-1"
            v-model="username"
            label="Username"
            :rules="[
              (value) => value.length >= 3 || `Should be at least 3 characters`,
              (value) => value.length <= 24 || `Should be at most 24 characters`,
              (value) => /^[0-9a-zA-Z-_]+$/.test(value) || `Only letters, numbers, hyphens, and underscores`,
            ]"
            :error-messages="usernameInputErrorMessage"
            :error="!!usernameInputErrorMessage"
            :messages="['Must contain letters, numbers, hyphens, and underscores']"
            outline
            @keyup="cleanUsername"
            :maxlength="24"
            autocapitalize="off"
          />
          <div class="button-bar">
            <va-button type="submit" class="full-width" v-bind:disabled="username.length < 3 || sendingUsernameRequest"
              >Confirm Username</va-button
            >
          </div>
        </va-form>
      </div>
    </div>
  </section>
</template>

<script>
import network from '@/network.js';
import afterLogin from '@/lib/after-login.js';

export default {
  name: 'ChooseUsernameScreen',

  data() {
    return {
      username: '',
      sendingUsernameRequest: false,
      sendingUsernameRequiredRequest: false,
      usernameInputErrorMessage: '',
    };
  },

  async created() {},

  methods: {
    async submitUsername() {
      this.sendingUsernameRequest = true;

      try {
        const result = await network.user.setUsername(this.username);
        this.$store.commit('setUsername', result.username);

        await afterLogin(this.$router);
      } catch (err) {
        if (err.code === 'invalid_request') {
          this.usernameInputErrorMessage = 'Must contain letters, numbers, hyphens, and underscores';
        } else if (err.code === 'registration_collision') {
          this.usernameInputErrorMessage = 'This username is already taken';
        } else {
          this.$vaToast.init({
            message: err.message,
            position: 'bottom-right',
            offsetX: 10,
            offsetY: 58,
            color: 'warning',
          });
        }
      }

      this.sendingUsernameRequest = false;
    },

    cleanUsername() {
      if (this.username.includes(' ')) {
        this.username = this.username.replaceAll(' ', '-');
      }

      if (this.username.match(/[^0-9a-z-]/)) {
        this.username = this.username.replaceAll(/[^0-9a-zA-Z-_]/g, '');
      }
    },
  },
};
</script>
