<template>
  <section class="screen">
    <SearchSidebar />

    <div class="padder" style="text-align: right">
      <va-form tag="form" @submit.prevent="performSearch">
        <va-input placeholder="Keywords" outline v-model="searchTerm" class="text-input mb-1" />

        <va-button :disabled="!searchTerm.length" type="submit" class="half-width" icon="search"
          >Search for Maps</va-button
        >
      </va-form>
    </div>

    <div class="scrollable-listing" ref="scrollableListing">
      <div class="padder" v-if="displaySearchResults">
        <h3>Search Results</h3>

        <div class="maps">
          <MapList :maps="searchResults" />
          <va-alert v-if="!searchResults.length" color="dark">
            Couldn't find any maps matching your search term.
          </va-alert>
        </div>
      </div>

      <div class="padder">
        <h3>Subscribed Maps</h3>

        <div v-if="$store.state.user" class="maps">
          <MapList :maps="subscribed" />
          <va-alert v-if="hasLoadedSubscribed && !subscribed.length" color="dark">
            Maps that you're subscribed to will appear here.
          </va-alert>
        </div>
        <div v-else class="maps">
          <va-alert color="dark">
            Once you create an account this will contain a list of your subscribed maps.
          </va-alert>
        </div>
      </div>

      <div class="padder" v-if="displaySuggestResults">
        <h3>Suggested Maps</h3>

        <div class="maps">
          <MapList :maps="suggestResults" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import network from '@/network.js';
import MapList from '@/components/map-list.vue';
import SearchSidebar from '@/components/search-sidebar.vue';

export default {
  name: 'SearchScreen',

  data() {
    return {
      searchTerm: '',
      displaySearchResults: false,
      displaySuggestResults: false,

      subscribed: [], // array of subscribed maps
      hasLoadedSubscribed: false,
      searchResults: [], // array of subscribed maps
      suggestResults: [], // array of suggested maps
    };
  },

  async created() {
    if (this.$store.state.user) {
      this.updateSubscribed();
    }

    this.performSuggest();
  },

  methods: {
    async performSearch() {
      this.displaySearchResults = false;
      const query = this.searchTerm.trim();

      if (!query) {
        this.searchResults = [];
        return;
      }

      try {
        this.searchResults = await network.map.search(query);
      } catch (err) {
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
        return;
      }

      // Scroll to top of screen, otherwise user might not see their search results
      this.$refs.scrollableListing.scrollTop = 0;

      this.displaySearchResults = true;
    },

    async performSuggest() {
      try {
        this.suggestResults = await network.map.suggest(this.$store.state.lastLocation);
      } catch (err) {
        console.error(err);
        return;
      }

      this.displaySuggestResults = !!this.suggestResults.length;
    },

    async updateSubscribed() {
      this.subscribed = await network.user.listSubscribed();
      this.hasLoadedSubscribed = true;
    },

    onMapVisit({ name }) {
      this.$router.push(`/map/${name}`);
    },
  },

  components: {
    MapList,
    SearchSidebar,
  },
};
</script>

<style scoped>
.scrollable-listing {
  max-height: calc(100% - 102px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
