<template>
  <va-card
    class="post"
    @click="
      onCardClick({
        id,
        map: ch,
      })
    "
    :stripe="true"
    stripe-color="black"
    color="#fff"
  >
    <va-card-content>
      <div class="text" v-bind:class="{ selectable }">{{ icon || DEFAULT_ICON }} {{ message }}</div>
      <!-- TODO: markdown render support -->
      <div v-if="metadata.markdown" class="text markdown" v-bind:class="{ selectable }">
        {{ metadata.markdown }}
      </div>
      <div class="detail">
        <span class="map-level-circle">
          <span>{{ levelData.name_short }}</span>
        </span>
        <span v-if="showUsername">
          <a class="username" @click.stop="$router.push(`/profile/${uname}`)">@{{ uname }}</a>
          &#8226;
        </span>
        <span v-if="!permanent">
          {{ createdRelative() }}
          &#8226;
        </span>
        <a v-if="!isEmbedded" class="map-name" @click.stop="$router.push(`/map/${ch}`)">#{{ ch }}</a>
        <span v-if="replies"> &#8226; {{ replies }} replies</span>
        <span v-if="nsfw" class="nsfw"> &#8226; <span>NSFW</span></span>
      </div>
      <va-card-actions @click.stop align="right">
        <va-button
          v-if="metadata.website"
          size="medium"
          icon="public"
          color="secondary"
          :href="metadata.website"
          target="_blank"
          @click.stop
        />

        <va-button
          v-if="metadata.calendar"
          size="medium"
          icon="event"
          color="secondary"
          :href="metadata.calendar"
          target="_blank"
          @click.stop
        />

        <va-button
          @click.stop
          v-if="metadata.twitter"
          size="medium"
          color="secondary"
          :href="metadata.twitter"
          target="_blank"
        >
          <img src="/img/twitter-24px.png" width="24" height="24" />
        </va-button>

        <va-button
          v-if="metadata.instagram"
          size="medium"
          color="secondary"
          :href="metadata.instagram"
          target="_blank"
          @click.stop
        >
          <img src="/img/instagram-24px.png" width="24" height="24" />
        </va-button>

        <va-button
          @click.stop
          v-if="metadata.facebook"
          size="medium"
          color="secondary"
          :href="metadata.facebook"
          target="_blank"
        >
          <img src="/img/facebook-24px.png" width="24" height="24" />
        </va-button>

        <va-button
          @click.stop
          v-if="metadata.linkedin"
          size="medium"
          color="secondary"
          :href="metadata.linkedin"
          target="_blank"
        >
          <img src="/img/linkedin-24px.png" width="24" height="24" />
        </va-button>

        <va-button
          v-if="metadata.email"
          size="medium"
          icon="email"
          color="secondary"
          :href="'mailto:' + metadata.email"
          target="_blank"
          @click.stop
        />

        <va-button
          v-if="metadata.phone"
          size="medium"
          icon="call"
          color="secondary"
          :href="'tel:' + String(metadata.phone).replaceAll(' ', '')"
          target="_blank"
          @click.stop
        />

        <va-button
          v-if="metadata.address"
          size="medium"
          icon="directions"
          color="secondary"
          :href="mapUrl"
          target="_blank"
          @click.stop
        />

        <va-button
          v-if="metadata.checkin"
          size="medium"
          :icon="getCheckinIcon"
          :disabled="isCheckedIn || isEmbedded"
          color="warning"
          @click.stop="
            checkin({
              id,
              map: ch,
            })
          "
        />
      </va-card-actions>
    </va-card-content>
  </va-card>
</template>

<script>
import timeDifference from '@/lib/time-diff.js';
import { MAP_URL } from '@/lib/platform.js';

export default {
  name: 'PostCard',

  props: {
    type: String,
    label: String, // when type is beacon then a letter label is provided
    permanent: Boolean,
    message: String,
    level: [String, Number],
    created: Date,
    id: String,
    color: String, // TODO: Deleting this makes the card dark red
    uname: String,
    uid: String,
    mine: Boolean,
    ch: String,
    metadata: Object,
    loc: Object,
    nsfw: Boolean,
    selectable: Boolean, // when enabled (e.g. on Thread screen), clicking text copies it
    replies: Number,
    isEmbedded: Boolean,
    levelData: Object,
    icon: String,
  },

  data() {
    return {
      DEFAULT_ICON: '⚫',
    };
  },

  computed: {
    isCheckedIn() {
      return this.metadata.checkin && this.$store.state.checkins.has(`${this.ch}:${this.id}`);
    },

    getCheckinIcon() {
      return this.isCheckedIn ? 'where_to_vote' : 'add_location_alt';
    },

    showUsername() {
      if (this.permanent && this.level > 5) {
        return false;
      }
      if (this.isEmbedded) {
        return false;
      }
      return !!this.uname;
    },

    mapUrl() {
      const address = this.metadata.address;
      const query = address.replace('\n', ' ').replace(' ', '+');

      return MAP_URL + query;
    },
  },

  methods: {
    // TODO: this value should automatically update as time passes
    createdRelative() {
      return timeDifference(this.created);
    },

    checkin({ id, map }) {
      if (this.isEmbedded) {
        console.warn('map is embedded so ignoring check-in');
        return;
      }

      this.$store.commit('triggerCheckin', {
        id,
        map,
      });
    },

    onCardClick({ id, map }) {
      if (this.isEmbedded) {
        console.warn('map is embedded so ignoring card click');
        return;
      }

      this.$router.push(`/post/${map}/${id}`);
    },
  },
};
</script>

<style scoped>
.post {
  margin: var(--gap-standard);
}
.va-card__content {
  padding: var(--gap-standard);
}
.va-card__actions {
  padding: var(--gap-standard) 0 0 0;
}
.detail {
  font-size: 13px;
  color: var(--color-grey-700);
}
.username span {
  font-weight: 700;
}
.nsfw span {
  background-color: var(--color-red-700);
  color: white;
  border-radius: 4px;
  padding: 0 3px;
}
.text {
  max-width: calc(100vw - 20px);
  overflow-x: auto;
  max-height: 160px;
  padding-bottom: var(--gap-standard);
}
.markdown {
  font-size: 12px;
  white-space: pre-wrap; /* line breaks */
}
.map-level-circle {
  margin-right: 5px;
}
</style>
