<template>
  <va-list v-if="maps.length">
    <va-list-item
      v-for="(map, index) in maps"
      :key="index"
      class="list-item"
      @click="this.$router.push(`/map/${map.name}`)"
    >
      <va-list-item-section avatar>
        <va-icon v-if="!map.icon" :name="map.has_password ? 'vpn_lock' : 'public'" color="primary" class="list-item-icon" />
        <va-icon v-else color="primary" size="small" class="list-item-icon emoji">{{ map.icon }}</va-icon>
      </va-list-item-section>

      <va-list-item-section>
        <va-list-item-label> #{{ map.name }} </va-list-item-label>

        <va-list-item-label caption>
          <span v-if="map.member_count >= 0">
            {{ map.member_count }} {{ map.member_count === 1 ? 'member' : 'members' }}
          </span>
          <span v-if="map.member_count >= 0 && map.member_level"> &#8226; </span>
          <span v-if="map.member_level">
            {{ map.member_level === 3 ? 'admin' : map.member_level === 2 ? 'member' : 'subscribed' }}</span
          >
        </va-list-item-label>
      </va-list-item-section>
    </va-list-item>
  </va-list>
</template>

<script>
export default {
  name: 'MapList',

  props: {
    maps: Array,
  },
};
</script>

<style scoped>
.list-item {
  padding: 5px 0;
  border-bottom: 1px solid var(--color-grey-300);
}

.list-item:first-child {
  border-top: 1px solid var(--color-grey-300);
}

.list-item-icon {
  border: 1px solid var(--color-grey-500);
  border-radius: 50%;
  padding: 5px;
  width: 36px;
  height: 36px;
}
.list-item-icon.emoji {
  text-align: center;
}
</style>
