<template>
  <div v-if="visible" class="shroud" @click="close"></div>
  <va-sidebar v-if="visible" width="80vw">
    <div class="padder">
      <div class="bottom-group">
        <div class="button-bar">
          <va-button class="full-width" @click="onCreateMap" icon="add_circle">Create New Map</va-button>
        </div>
      </div>
    </div>
  </va-sidebar>
</template>

<script>
export default {
  name: 'SearchSidebar',

  computed: {
    visible() {
      return !!this.$store.state.triggerMenuSidebar;
    },
  },

  data() {
    return {};
  },

  methods: {
    onCreateMap() {
      this.$router.push('/map-create');
    },

    close() {
      this.$store.commit('disableMenu');
    },
  },
};
</script>

<style scoped></style>
