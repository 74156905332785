import { createStore } from 'vuex';
import Countly from 'countly-sdk-web';
import uuid from '@/lib/uuid.js';

const KEY = 'radar';

const PERSIST_TRIGGERS = new Set([
  'setLocation',
  'setUser',
  'setAuth',
  'enableGeolocation',
  'finishTutorial',
  'setUsername',
  'setDebug',
  'setBeta',
  'setNsfw',
  'blockUser',
  'unblockUser',

  'setLevels',
  'setCheckins',
]);

const DEFAULT_GEO = {
  acc: 1_000,
  lat: 37.76869047926061,
  lon: -122.47532227629989,
  fake: true,
};

const persist = (store) => {
  store.subscribe(({ type }, state) => {
    if (!PERSIST_TRIGGERS.has(type)) return;

    const persistable = {
      geo: state.geo,
      tutorial: state.tutorial,
      user: state.user,
      authHeader: state.authHeader,
      username: state.username,
      beta: state.beta,
      debug: state.debug,
      nsfw: state.nsfw,
      blocked: state.blocked,
      lastLocation: state.lastLocation,
      session: state.session,

      levels: state.levels,
      checkins: [...state.checkins],
    };

    localStorage.setItem(KEY, JSON.stringify(persistable));
  });
};

const store = createStore({
  plugins: [persist],

  state: {
    // PERSIST, add to list above
    geo: false, // consent to geo permission request
    tutorial: false, // completed the tutorial
    user: null, // user ID
    authHeader: null, // auth header
    username: null,
    beta: false,
    debug: false,
    nsfw: false,
    blocked: {}, // user ID -> username
    session: null,

    // PERSIST, to cache common network responses
    levels: null,
    checkins: new Set(), // stored as array

    // EPHEMERAL PROPS, only to trigger view changes
    loginExpired: false, // trigger login modal
    lastLocation: {
      acc: DEFAULT_GEO.acc,
      lat: DEFAULT_GEO.lat,
      lon: DEFAULT_GEO.lon,
      fake: DEFAULT_GEO.fake,
    },
    lastViewport: null,
    appVisible: true,
    online: true,
    gpsIsOkay: false,

    navbar: true,
    header: true,
    headerTitle: '',
    headerSubtitle: '',

    lastVisitedMap: 'home',
    firstVisitedMap: null,

    triggerCheckin: false,
    checkinData: null,
    triggerMenuSidebar: false,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },

    setAuth(state, authHeader) {
      state.authHeader = authHeader;
    },

    enableGeolocation(state) {
      state.geo = true;
    },

    finishTutorial(state) {
      state.tutorial = true;
    },

    setUsername(state, username) {
      state.username = username;
      Countly.userData.set('username', username);
      Countly.userData.save();
    },

    setBeta(state, beta) {
      state.beta = beta;
    },

    setDebug(state, debug) {
      state.debug = debug;
    },

    setNsfw(state, nsfw) {
      state.nsfw = nsfw;
    },

    blockUser(state, { username, userId }) {
      state.blocked[userId] = username;
    },

    unblockUser(state, uid) {
      delete state.blocked[uid];
    },

    setLocation(state, { acc, lat, lon, fake = false }) {
      state.lastLocation = {
        acc,
        lat,
        lon,
        fake,
      };
      state.gpsIsOkay = true;
    },

    setViewport(state, { lat, lon, zoom }) {
      state.lastViewport = {
        lat,
        lon,
        zoom,
      };
    },

    setGpsError(state) {
      state.gpsIsOkay = false;
    },

    setLoginExpired(state) {
      state.loginExpired = true;
    },

    unsetLoginExpired(state) {
      state.loginExpired = false;
    },

    triggerCheckin(state, checkinData) {
      state.triggerCheckin = true;
      state.checkinData = checkinData;
    },

    disableCheckin(state) {
      state.triggerCheckin = false;
      state.checkinData = null;
    },

    addCheckin(state, { map, post }) {
      state.checkins.add(`${map}:${post}`);
    },

    setCheckins(state, checkins) {
      state.checkins = new Set();
      for (let checkin of checkins) {
        state.checkins.add(`${checkin.map_name}:${checkin.post_id}`);
      }
    },

    setLevels(state, levels) {
      state.levels = levels;
    },

    setVisitedMap(state, map) {
      state.lastVisitedMap = map;

      if (!state.firstVisitedMap && map !== 'home' && map !== 'global' && map !== 'suggested') {
        state.firstVisitedMap = map;
      }
    },

    setTitle(state, { title = null, subtitle = null }) {
      state.header = !!title; // TODO
      state.headerTitle = title;
      state.headerSubtitle = subtitle;
    },

    setOnline(state, status) {
      state.online = status;
    },

    toggleMenu(state) {
      state.triggerMenuSidebar = !state.triggerMenuSidebar;
    },

    disableMenu(state) {
      state.triggerMenuSidebar = false;
    },
  },

  actions: {
    wipe(_context) {
      // TODO: Should probably clear in memory state too
      localStorage.removeItem(KEY);
    },

    load(context) {
      const raw = localStorage.getItem(KEY);

      if (!raw) {
        console.log('NO EXISTING STATE');
        context.state.session = uuid();
        return false;
      }

      try {
        var parsed = JSON.parse(raw);
      } catch (err) {
        console.error('CORRUPTED STATE WAS LOADED!', err);
        // TODO: display message to user
        context.state.session = uuid();
        return false;
      }

      console.log('RESTORING PREVIOUS STATE');

      for (let key of Object.keys(parsed)) {
        context.state[key] = parsed[key];
      }

      context.state.checkins = new Set(context.state.checkins);

      if (!context.state.session) {
        context.state.session = uuid();
      }

      setTimeout(() => {
        Countly.userData.set('username', parsed.username);
        Countly.userData.set('session', context.state.session);
        Countly.userData.save();
      }, 1000);

      return true;
    },
  },
});

// This is for debugging purposes only.
// No code should ever depend on it.
window.STORE = store;

export default store;
