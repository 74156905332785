<template>
  <section class="screen">
    <div class="padder">
      <p>Creating a Map Buddy account lets you do the following:</p>

      <ul>
        <li>Check-in to certain posts</li>
        <li>Create and favorite posts</li>
        <li>Reply in post conversations</li>
        <li>Create and subscribe to maps</li>
      </ul>

      <p>Have an <router-link to="/authcode">Auth Code</router-link>?</p>
    </div>

    <div class="bottom-group">
      <!-- using v-show instead of v-if due to race condition with Google -->
      <div class="bottom-divider">
        <div class="button-bar">
          <va-button
            v-if="THIRD_PARTY_LOGIN === 'apple'"
            color="secondary"
            class="apple-signin"
            :href="this.APPLE_LOGIN_URL"
            >Sign in with Apple</va-button
          >
          <div v-else-if="THIRD_PARTY_LOGIN === 'google'" class="google-signin" ref="googleSignin"></div>
        </div>
      </div>

      <va-form tag="form" @submit.prevent="submitEmail">
        <va-input
          class="text-input mb-1"
          v-model="emailAddress"
          v-bind:disabled="sendingEmailRequest"
          type="email"
          label="Email Address"
          placeholder="user@domain.tld"
          outline
          :rules="[(value) => (value.includes('@') && value.includes('.')) || `Invalid email address`]"
          :error-messages="emailInputErrorMessage"
          :error="!!emailInputErrorMessage"
        />

        <div class="terms">
          By signing up you agree to the
          <a href="https://mapbuddy.app/terms-of-service.html" target="_blank">Terms of Service</a>.
        </div>

        <div class="button-bar">
          <va-button
            type="submit"
            class="full-width"
            icon="email"
            v-bind:disabled="!(emailAddress.includes('@') && emailAddress.includes('.')) || sendingEmailRequest"
            >Email me a Login Link</va-button
          >
        </div>
      </va-form>
    </div>
  </section>
</template>

<script>
import network from '@/network.js';
import { PLATFORM, IS_APPLE } from '@/lib/platform.js';
import { authenticateGoogleIdentity } from '@/lib/authenticate.js';

// TODO: Temporary while both Radar Chat and Map Buddy are used
const GOOGLE_CLIENT_ID = location.host.includes('mapbuddy')
  ? '504050438612-5s4705ocldmelrkmdlgn3nir01fa5evq.apps.googleusercontent.com' // Map Buddy
  : '504050438612-1qi7h5qsc0u8fgk267i3d1pva36t9p4m.apps.googleusercontent.com'; // Radar Chat

const APPLE_LOGIN_URL = `https://appleid.apple.com/auth/authorize?response_type=code&redirect_uri=${network.APPLE_REDIRECT}&client_id=chat.radar.app.auth`;
const THIRD_PARTY_LOGIN = PLATFORM === 'ios' || IS_APPLE ? 'apple' : 'google';

export default {
  name: 'AccountCreateScreen',

  data() {
    return {
      warn: 'warn' in this.$route.query,
      sendingEmailRequest: false,
      emailAddress: '',
      emailInputErrorMessage: '',
      enableAppleSignin: false,
      APPLE_LOGIN_URL,
      THIRD_PARTY_LOGIN,
    };
  },

  created() {
    if (this.$store.state.username) {
      console.log('username already set, user must be ready');
      this.$router.replace('/');
      return;
    } else if (this.$store.state.user && this.$store.state.authHeader) {
      console.log('no username but is logged in, must need to set set username');
      this.$router.replace('/username');
      return;
    }

    if (this.warn) {
      this.$vaToast.init({
        message: 'The feature you tried to use requires an account!',
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58 + 44, // button visibility
        color: 'info',
      });
    }

    if (window.google && window.google.accounts && THIRD_PARTY_LOGIN === 'google') {
      try {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          cancel_on_tap_outside: true,
          callback: this.handleGoogleCredentialResponse,
        });

        // The $ref doesn't exist at time of creation
        this.$nextTick(() => {
          window.google.accounts.id.renderButton(this.$refs.googleSignin, {
            theme: 'outline',
            size: 'large',
            width: 240,
          });
        });
      } catch (err) {
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }

      // window.google.accounts.id.prompt();
    } else if (THIRD_PARTY_LOGIN === 'google') {
      // TODO: If the user immediately opens to the this screen they can't use Google Identity
      console.error('The Google account script has not loaded yet. Google login is disabled.');
    }
  },

  methods: {
    async submitEmail() {
      this.sendingEmailRequest = true;
      try {
        const result = await network.auth.generateAuthEmail(this.emailAddress, this.$store.state.lastLocation);
        if (result) {
          this.$router.push('/authcode');
        } else {
          console.error('error submitting email');
        }
      } catch (err) {
        if (err.code === 'invalid_request') {
          this.emailInputErrorMessage = 'Invalid email address';
        } else {
          this.$vaToast.init({
            message: err.message,
            position: 'bottom-right',
            offsetX: 10,
            offsetY: 58,
            color: 'warning',
          });
        }
      }
      this.sendingEmailRequest = false;
    },

    async handleGoogleCredentialResponse(response) {
      try {
        await authenticateGoogleIdentity(response, this.$router);
      } catch (err) {
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }
    },
  },
};
</script>

<style scoped>
.terms {
  font-size: 14px;
  text-align: center;
  padding: 0 0 10px 0;
  color: var(--color-xanadu);
}

.bottom-divider {
  border-bottom: 1px solid var(--color-grey-300);
  margin-bottom: var(--gap-standard);
  padding-bottom: var(--gap-standard);
  max-height: calc(44px + var(--gap-standard));
}

.google-signin > div {
  margin: 0 auto;
  text-align: center;
}
</style>
