import store from '@/store.js';
// TODO: Is it possible to import 'toast' from Vue without needing to pass it as an arg?

const FLAGS = { enableHighAccuracy: true };

let watchId;

export function getCurrentPosition(toast, cb) {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      store.commit('enableGeolocation');

      const lastLocation = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        acc: position.coords.accuracy,
      };

      store.commit('setLocation', lastLocation);

      if (cb) cb(undefined, lastLocation);
    },

    (error) => {
      geoError(toast, error);

      if (cb) cb(error);
    },

    FLAGS
  );
}

export function watchPosition(toast, cb) {
  if (watchId) {
    // This can happen during local dev when reload happens
    console.warn('Double watched position; clearing old watch.');
    clearWatch();
  }

  watchId = navigator.geolocation.watchPosition(
    (position) => {
      const lastLocation = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        acc: position.coords.accuracy,
      };

      store.commit('setLocation', lastLocation);

      if (cb) cb(undefined, lastLocation);
    },

    (error) => {
      geoError(toast, error);

      if (cb) cb(error);
    },

    FLAGS
  );
}

export function isWatching() {
  return !!watchId;
}

export function clearWatch() {
  navigator.geolocation.clearWatch(watchId);
}

function geoError(toast, error) {
  let errorMessage = 'Unable to get current location.';
  let errorColor = 'warning';

  if (error.code === error.PERMISSION_DENIED) {
    errorMessage = "Map Buddy doesn't have permission to access your location!";
    errorColor = 'danger';
  } else if (error.code === error.POSITION_UNAVAILABLE) {
    errorMessage = 'Your device is currently unable to determine the current location.';
  } else if (error.code === error.TIMEOUT) {
    errorMessage = 'Your device timed out when getting the current location.';
  }

  store.commit('setGpsError');

  toast.init({
    message: errorMessage,
    position: 'bottom-right',
    offsetX: 10,
    offsetY: 58,
    color: errorColor,
  });
}
