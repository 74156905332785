<template>
  <section class="screen simple-message">
    <div class="bullseye">Redirecting...</div>
  </section>
</template>

<script>
import network from '@/network.js';

export default {
  name: 'QrRedirectScreen',

  async created() {
    const code = this.$route.params.code;

    try {
      const result = await network.qr.get(code);

      console.log('QR Code Result', result);

      if (result && result.partial) {
        this.$router.replace(result.partial);

        this.$vaToast.init({
          message: 'QR Code scan success!',
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'dark',
        });
      } else {
        this.$router.replace('/');

        this.$vaToast.init({
          message: 'There was an error looking up the QR code. Please try again.',
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }
    } catch (err) {
      this.$router.replace('/');

      this.$vaToast.init({
        message: 'There was an error looking up the QR code. Please try again.',
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'warning',
      });
    }
  },
};
</script>
