<template>
  <section class="screen">
    <Modal
      v-if="showBlockDialog"
      title="Block User"
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Block"
      @cancel="onBlockCancel"
      @confirm="onBlockSubmit"
    >
      <div style="margin-bottom: 10px">
        You are about to block user <strong>@{{ userData.username }}</strong
        >. Their posts will be hidden from you on this device. Are you sure?
      </div>
    </Modal>

    <div v-if="loaded" class="padder">
      <h3>Avatar</h3>
      <img class="avatar" :src="userData.avatar" alt="Avatar Image" />
      <p>
        <small
          >Avatars are currently implemented using <a href="https://en.gravatar.com/" target="_blank">Gravatar</a>.
          Create an account using the same email you signed up for on Map Buddy to create your avatar.</small
        >
      </p>
    </div>

    <div v-if="loaded" class="padder">
      <p>Account Created: {{ created.toLocaleDateString() }} {{ created.toLocaleTimeString() }}</p>
    </div>

    <div v-if="loaded" class="bottom-group">
      <va-button
        class="full-width"
        color="warning"
        icon="person_off"
        v-if="!userIsBlocked"
        :disabled="lookingAtMyself"
        @click="onBlock"
        >Block User</va-button
      >
      <va-button class="full-width" icon="person" v-if="userIsBlocked" @click="onUnBlock">Unblock User</va-button>
    </div>
  </section>
</template>

<script>
import network from '@/network.js';
import Modal from '@/components/modal.vue';

export default {
  name: 'ProfileScreen',

  data() {
    return {
      loaded: false,
      lookingAtMyself: false,
      username: '',
      userData: {},
      created: new Date(),

      userIsBlocked: false,

      showBlockDialog: false,
    };
  },

  async created() {
    await this.refresh();
  },

  methods: {
    async refresh() {
      this.username = this.$route.params.username;
      this.lookingAtMyself = this.username === this.$store.state.username;
      this.$store.commit('setTitle', { title: 'User', subtitle: `@${this.username}` });

      this.userData = await network.user.get(this.username);

      this.created = new Date(this.userData.created);

      this.userIsBlocked = this.userData.id in this.$store.state.blocked;

      this.loaded = true;
    },

    async onUnBlock() {
      this.$store.commit('unblockUser', this.userData.id);

      this.$vaToast.init({
        message: `User @${this.username} is no longer blocked on this device.`,
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'info',
      });

      this.userIsBlocked = false;
    },

    async onBlock() {
      this.showBlockDialog = true;
    },

    onBlockCancel() {
      this.showBlockDialog = false;
    },

    async onBlockSubmit() {
      const username = this.userData.username;
      const userId = this.userData.id;

      this.$store.commit('blockUser', { username, userId });

      this.showBlockDialog = false;

      this.$vaToast.init({
        message: `User @${username} has been blocked on this device. You can unblock them from the settings screen.`,
        position: 'bottom-right',
        offsetX: 10,
        offsetY: 58,
        color: 'info',
      });

      this.userIsBlocked = true;
    },
  },

  components: {
    Modal,
  },
};
</script>

<style scoped>
.avatar {
  max-width: 25vh;
  max-height: 25vh;
  margin: var(--gap-standard) auto;
}
</style>
