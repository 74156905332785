<template>
  <va-modal
    v-model="loginExpired"
    message="Your login session has expired. You'll need to log in again to keep using the app."
    title="Login Session Expired"
    :mobile-fullscreen="false"
    ok-text="Log In"
    @ok="resetLogin"
    @cancel="cancelReset"
  />

  <va-modal
    v-model="displayCheckinDialog"
    title="Checkin Confirm"
    :mobile-fullscreen="false"
    ok-text="Check-In"
    @cancel="cancelPostCheckin"
    @ok="onPostCheckinConfirm"
  >
    <slot>
      <div>
        By checking into this post you agree to share your current location and email address with the moderators of the
        <strong>#{{ checkinData?.mapName }}</strong> map and agree to their
        <a :href="checkinData?.privacyPolicy" target="_blank">privacy policy</a>.
      </div>
    </slot>
  </va-modal>

  <HeaderBar v-if="!isEmbedded" />

  <router-view v-if="requiredDataIsReady" />
  <section v-else class="screen simple-message">
    <div class="bullseye">Checking for Data Updates...</div>
  </section>

  <NavBar v-if="!isEmbedded" />
</template>

<script>
import NavBar from '@/components/nav-bar.vue';
import HeaderBar from '@/components/header-bar.vue';
import network from '@/network.js';
import { isWatching, watchPosition, clearWatch } from '@/lib/location-prompt.js';
import { ROOT_URL, PLATFORM, INSTALLED } from '@/lib/platform.js';

export default {
  name: 'App',

  data() {
    return {
      watchId: null,

      isEmbedded: location.pathname.startsWith('/map-embed'),

      displayCheckinDialog: false,
      checkinData: null,

      requiredDataIsReady: false,
    };
  },

  created() {
    if (this.$store.state.levels) {
      console.log('already has levels');
      this.requiredDataIsReady = true;
    }

    document.body.classList.add(`platform-${PLATFORM}`);

    // Injects a meta tag that displays installable app banner on iOS devices
    if (!this.isEmbedded && PLATFORM === 'ios' && !INSTALLED) {
      const head = document.getElementsByTagName('head')[0];
      const meta = document.createElement('meta');
      meta.name = 'apple-itunes-app';
      // TODO: Should pass current URL along, appending ", app-argument=URL"
      meta.content = 'app-id=1599975217';
      head.appendChild(meta);
    }

    // App should never change between embedded an normal modes
    // Embedded is used when embedded in a website
    // Normal mode is when the app is running normally
    if (this.isEmbedded) {
      document.body.classList.add('embedded');
    }

    if (process.env.NODE_ENV === 'production') {
      this.$Countly.q.push(['track_pageview']);
      this.$Countly.q.push(['track_errors']);
      this.$Countly.begin_session();
    } else {
      this.$Countly.q.push(['opt_out']);
    }

    if (this.$store.state.geo) {
      console.log('Geo was enabled, probably refreshed, watching geo.');
      this.enableGeolocationWatcher();
    } else {
      console.log('Geo was not enabled, might be fresh launch, using geoip.');
      network.misc.getGeoIp().then((geo) => {
        geo.fake = true;
        this.$store.commit('setLocation', geo);
      }).catch((err) => {
        console.error(err);
      });
    }

    this.$watch(
      () => this.$store.state.geo,
      (value) => {
        if (value && !isWatching()) {
          console.log('Enabling geolocation watch');
          this.enableGeolocationWatcher();
        } else if (!value && this.watchId) {
          console.log('Disabling geolocation watch');
          this.disableGeolocationWatcher();
        } else {
          console.error('Not sure what to do with geolocation...', value, this.watchId);
        }
      }
    );

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        this.$vaToast.init({
          duration: 2500,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'dark',
          message: 'App was recently updated. You may need to restart to update.',
        });
      });
    }

    this.$store.state.appVisible = true;
    document.addEventListener('visibilitychange', () => {
      this.$store.state.appVisible = document.visibilityState === 'visible';
    });

    document.body.addEventListener('click', (event) => {
      const target = event.target;

      if (target && target.classList.contains('selectable')) {
        navigator.clipboard.writeText(target.innerText);

        this.$vaToast.init({
          duration: 2500,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'dark',
          message: 'Copied text to clipboard.',
        });
      }
    });

    {
      this.$store.commit('setOnline', navigator.onLine);
      window.addEventListener('online', () => {
        this.$store.commit('setOnline', true);
      });
      window.addEventListener('offline', () => {
        this.$store.commit('setOnline', false);
      });
    }

    // Load levels and checkins
    // Note that this data is cached in localStorage, but should still try to be fresh
    (async () => {
      const levels = await network.misc.getLevels();

      this.$store.commit('setLevels', levels);

      if (!this.$store.state.user) {
        this.requiredDataIsReady = true;
        return;
      }

      const result = await network.checkin.getMyCheckins();
      this.$store.commit('setCheckins', result);
      this.requiredDataIsReady = true;
    })();
  },

  computed: {
    // Dismissing the dialog displays a warning that loginExpired is read only.
    // I believe this is due to the v-model trying to set the value to false upon close.
    // It doesn't seem to be a big deal as cancelReset() sets this to false anyways.
    loginExpired() {
      return this.$store.state.loginExpired;
    },

    triggerCheckin() {
      return this.$store.state.triggerCheckin;
    },
  },

  watch: {
    triggerCheckin(triggering) {
      if (triggering) {
        this.onPostCheckin(this.$store.state.checkinData);
      }
    },
  },

  methods: {
    resetLogin() {
      this.$store.dispatch('wipe');
      // this.$router.push('/'); // Route guard immediately kicks user back to map view
      location.href = ROOT_URL;
    },

    cancelReset() {
      this.$store.commit('unsetLoginExpired');
    },

    enableGeolocationWatcher() {
      if (this.isEmbedded) {
        console.warn('App is embedded. Preventing Geolocation watch.');
        return;
      }

      watchPosition(this.$vaToast);
    },

    disableGeolocationWatcher() {
      clearWatch();
    },

    async onPostCheckin({ map, id }) {
      if (!this.$store.state.user) {
        this.$store.commit('disableCheckin');
        this.$router.push('/account-create');
        return;
      }

      try {
        const chan = await network.map.get(map);

        this.checkinData = {
          privacyPolicy: chan.capabilities.checkin,
          mapName: map,
          postId: id,
        };

        this.displayCheckinDialog = true;
      } catch (err) {
        this.$store.commit('disableCheckin');
        this.$vaToast.init({
          message: err.message,
          position: 'bottom-right',
          offsetX: 10,
          offsetY: 58,
          color: 'warning',
        });
      }
    },

    async cancelPostCheckin() {
      this.displayCheckinDialog = false;
      this.$store.commit('disableCheckin');
    },

    async onPostCheckinConfirm() {
      try {
        const result = await network.checkin.checkin(
          this.checkinData.mapName,
          this.checkinData.postId,
          this.$store.state.lastLocation
        );

        this.$store.commit('addCheckin', {
          map: this.checkinData.mapName,
          post: result.post_id,
        });
      } catch (err) {
        if (err.code === 'checkin_too_far') {
          this.$vaToast.init({
            message: 'You are too far from this location to check in! Try getting closer.',
            position: 'bottom-right',
            offsetX: 10,
            offsetY: 58,
            color: 'warning',
          });
        } else {
          this.$vaToast.init({
            message: err.message,
            position: 'bottom-right',
            offsetX: 10,
            offsetY: 58,
            color: 'warning',
          });
        }
      }

      this.$store.commit('disableCheckin');
      this.checkinData = null;
    },
  },

  components: {
    NavBar,
    HeaderBar,
  },
};
</script>
