<template>
  <GoogleMap
    ref="mapRef"
    api-key="AIzaSyCiIWyc0xzdsrkZeBsHOjwwXbk_ipA1V7A"
    style="width: 100%; height: 100%"
    :center="center"
    :disableDefaultUi="true"
    :clickableIcons="false"
    :keyboardShortcuts="false"
    :zoomControl="allowZoom"
    :gestureHandling="allowPan ? 'greedy' : 'none'"
    :styles="styles"
    :tilt="0"
    :zoom="15"
    :maxZoom="20"
    :minZoom="8"
    @click="onClick"
  >
    <MapCircle
      key="input-crc"
      v-if="postRadius"
      ref="postRadiusRef"
      :options="{
        center: { lat: userPosition.lat, lng: userPosition.lon },
        radius: postRadius,
        strokeColor: '#22333b',
        strokeOpacity: 0.75,
        strokeWeight: 1,
        fillColor: '#22333b',
        fillOpacity: 0.1,
      }"
      @click="onClick"
    />

    <MapCircle
      key="usr-crc"
      :options="{
        center: { lat: userPosition.lat, lng: userPosition.lon },
        radius: userPosition.acc,
        strokeWeight: 0,
        fillColor: '#3772e0',
        fillOpacity: 0.1,
      }"
      @click="onClick"
    />

    <div v-if="circleRadiusEnabled">
      <MapCircle v-bind:key="'msg-crc-' + post.id" v-for="post in posts" :options="post.circle" @click="onClick" />
    </div>

    <CustomMarker
      v-bind:key="'msg-mrk-' + post.id"
      v-for="post in posts"
      @click.stop="onMarkerClick(post.id, post.ch)"
      class="custom-marker-content"
      :options="{
        anchorPoint: 'LEFT_CENTER',
        position: { lat: post.loc.lat, lng: post.loc.lon },
        offsetX: -10,
      }"
    ><span class="icon">{{ post.icon || DEFAULT_ICON }}</span><span v-if="!disableMessage" class="map-label">{{ post.message }}</span></CustomMarker>

    <MarkerCluster v-if="!noCluster" :options="{onClusterClick}">
      <CustomMarker
        v-bind:key="'bcn-mrk-' + beacon.id"
        v-for="beacon in beacons"
        @click.stop="onMarkerClick(beacon.id, beacon.ch)"
        class="custom-marker-content"
        :options="{
          anchorPoint: 'LEFT_CENTER',
          position: { lat: beacon.loc.lat, lng: beacon.loc.lon },
          offsetX: -10,
        }"
      ><span class="icon">{{ beacon.icon || DEFAULT_ICON }}</span><span v-if="!disableMessage" class="map-label">{{ beacon.message }}</span></CustomMarker>
    </MarkerCluster>
    <div v-else>
      <CustomMarker
        v-bind:key="'bcn-mrk-' + beacon.id"
        v-for="beacon in beacons"
        @click.stop="onMarkerClick(beacon.id, beacon.ch)"
        class="custom-marker-content"
        :options="{
          anchorPoint: 'LEFT_CENTER',
          position: { lat: beacon.loc.lat, lng: beacon.loc.lon },
          offsetX: -10,
        }"
      ><span class="icon">{{ beacon.icon || DEFAULT_ICON }}</span><span v-if="!disableMessage" class="map-label">{{ beacon.message }}</span></CustomMarker>
    </div>

    <MapMarker
      key="usr-mrk"
      :options="{
        icon: {
          anchor: {
            x: 10,
            y: 10,
          },
          url: userMarkerSvg,
          scaledSize: {
            width: 20,
            height: 20,
          },
        },
        position: { lat: userPosition.lat, lng: userPosition.lon },
      }"
      @click="onClick"
    />
  </GoogleMap>

  <div v-if="allowPan && origin" class="map-controls top">
    <va-button color="#fff" @click="goToOrigin" icon="anchor" />
  </div>

  <div v-if="allowPan" class="map-controls bottom hide-if-embedded">
    <va-button color="#fff" @click="centerMap" :icon="getIcon" />
  </div>
</template>

<script>
import { GoogleMap, MarkerCluster, CustomMarker, Circle as MapCircle, Marker as MapMarker } from 'vue3-google-map';
import { ref } from 'vue';
import { distance } from '@/lib/geo.js';
import { debounce } from '@/lib/debounce.js';

// Google Map instance
const mapRef = ref(null);
const postRadiusRef = ref(null);

function userSvg(color, border) {
  return `<?xml version="1.0"?><svg width="26px" height="26px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><circle stroke="${border}" stroke-width="12" fill="${color}" cx="50" cy="50" r="35"/></svg>`;
}

const USER_COLOR = '#3772e0';
const USER_MARKER = userSvg(USER_COLOR, '#FFF');
const DEFAULT_ZOOM = 15;
const DEFAULT_FAKE_ZOOM = 9;

// This is the `minimal` theme provided by vue3-google-map
// TODO: The theme import example doesn't work. Shouldn't need to ship JSON by hand.
import styles from './map-style.json';

export default {
  name: 'GoogleMapWrapper',

  components: {
    GoogleMap,
    MapCircle,
    MapMarker,
    MarkerCluster,
    CustomMarker,
  },

  props: {
    userPosition: Object,
    posts: Array,
    beacons: Array,
    postRadius: Number,
    mapDataHasLoaded: Boolean,
    origin: Object,
    viewportOverride: Object,
    noCluster: Boolean,
    disableMessage: Boolean,
    allowZoom: {
      default: true,
      type: Boolean,
    },
    allowPan: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      center: {
        lat: this.userPosition.lat,
        lng: this.userPosition.lon,
      },

      DEFAULT_ICON: '⚫',

      follow: true,

      userMarkerSvg: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(USER_MARKER)}`,

      styles,

      circleRadiusEnabled: this.$store.state.debug,
    };
  },

  computed: {
    getIcon() {
      if (this.follow) {
        return 'gps_fixed';
      }
      return 'gps_not_fixed';
    },
  },

  methods: {
    centerMap() {
      this.follow = true;

      mapRef.value.map.panTo({
        lat: this.userPosition.lat,
        lng: this.userPosition.lon,
      });
    },

    goToOrigin() {
      this.follow = false;

      mapRef.value.map.panTo({
        lat: this.origin.lat,
        lng: this.origin.lon,
      });

      if (this.origin.zoom) {
        mapRef.value.map.setZoom(this.origin.zoom);
      }
    },

    goToViewportOverride() {
      this.follow = false;

      mapRef.value.map.panTo({
        lat: this.viewportOverride.lat,
        lng: this.viewportOverride.lon,
      });
      mapRef.value.map.setZoom(this.viewportOverride.zoom);
    },

    onMarkerClick(postId, channel) {
      console.log('CLICK', postId);
      this.$emit('focusMarker', { id: postId, channel });
    },

    onDrag() {
      if (!this.follow) return;
      this.follow = false;
    },

    async onBounds(center, width, height, zoom) {
      this.$emit('bounds', { center, width, height, zoom });
    },

    onClick() {
      // ideally this will fire only when clicking on nothing, not when clicking on a marker
      // screen clicks when using mobile seem to have a "gravity", like nearby clickable things are clicked when clicking nothing
      // for that reason, the user marker needs to be clickable and to trigger this onClick
      // also, the circles also absorb a click, so those need to be clickable too
      // it's then important that all that stuff is below the real markers
      this.$emit('click');
    },

    onClusterClick(_event, cluster, map) {
      this.follow = false;
      map.fitBounds(cluster.bounds);
    },
  },

  emits: ['bounds', 'focusMarker', 'click'],

  setup() {
    return {
      mapRef,
      postRadiusRef,
    };
  },

  created() {
    // Keep checking to see if the map is ready. Once it is, establish an event listener.
    const int = setInterval(() => {
      if (!mapRef.value || !mapRef.value.map) return;

      clearInterval(int);

      const map = mapRef.value.map;

      map.addListener('dragstart', () => {
        this.onDrag();
      });

      if (this.postRadius) {
        const circle = postRadiusRef.value.circle;

        circle.addListener('radius_changed', () => {
          map.fitBounds(circle.getBounds());
        });
      }

      const boundsChange = debounce(() => {
        const bounds = map.getBounds();

        if (!bounds) {
          console.warn('cannot trigger bounds change as map is not ready');
          return;
        }

        const zoom = map.getZoom();

        let center = bounds.getCenter();
        center = { lat: center.lat(), lon: center.lng() };

        let corner = bounds.getNorthEast();
        corner = { lat: corner.lat(), lon: corner.lng() };

        // Measure distance from center to a corner and double it
        // Technically we could just measure from both corners and not double it
        // However, we already have the center
        const width = Math.ceil(distance(center, { lat: center.lat, lon: corner.lon }) * 2);
        const height = Math.ceil(distance(corner, { lat: center.lat, lon: corner.lon }) * 2);
        this.onBounds(center, width, height, zoom);

        this.$store.commit('setViewport', {
          lat: center.lat,
          lon: center.lon,
          zoom,
        });
      }, 500);

      // TODO: Can get fired after leaving screen resutling in network error
      boundsChange(); // trigger immediately

      map.addListener('bounds_changed', boundsChange);

      // The following code jumps to the map's origin
      // but it only applies when not posting a post
      // TODO: Need a cleaner way to differentiate map purpose
      if (!this.postRadius) {
        // postRadius is set when on create post screen
        if (this.viewportOverride) {
          // the viewport override should be set when map first loads
          this.goToViewportOverride();
        } else if (this.origin) {
          // Previously this only jumped to origin if origin exists AND position is fake
          this.goToOrigin();
        } else if (!this.mapDataHasLoaded) {
          console.warn('map data not yet available at time of map render');
          const unwatch = this.$watch(
            () => this.origin,
            (newOrigin) => {
              unwatch();
              if (!newOrigin) {
                return;
              }
              this.goToOrigin();
            }
          );
        } else if (this.userPosition.fake) {
          console.log('user position is fake, zooming out');
          mapRef.value.map.setZoom(DEFAULT_FAKE_ZOOM);
        }
      }
    }, 100);
  },

  watch: {
    userPosition(newPosition, oldPosition) {
      if (!this.follow) return;

      this.center = {
        lat: newPosition.lat,
        lng: newPosition.lon,
      };

      if (!newPosition.fake && oldPosition.fake) {
        console.log('user position changed from fake to real, while following, zooming in');
        mapRef.value.map?.setZoom(DEFAULT_ZOOM); // race condition, map might not be ready
      }
    },
  },
};
</script>

<style scoped>
.map-controls {
  position: absolute;
  right: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: #fff;
  padding: 2px;
}
.map-controls.top {
  bottom: 165px;
}
.map-controls.bottom {
  bottom: 115px;
}
</style>

<style>
body.embedded .map-controls.top {
  bottom: 115px;
}
.map-controls button i {
  color: #666;
}
.custom-marker-content {
  z-index: 2000000; /* Google Cluster elements have 1000000++ indexes */
  font-size: 16px;
  vertical-align: middle;
}
.map-label {
  font-size: 11px;
  color: var(--color-gunmetal);
  /*
  background-color: #ffffff99;
  color: #fff;
  background-color: #0009;
  */
  padding: 2px;
  border-radius: 2px;
  max-width: 200px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: -3px;
  display: inline-block;
  text-shadow:
   -1px -1px 0 #fff,  
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff;
}
</style>
