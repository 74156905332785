<template>
  <section class="screen simple-message">
    <div class="bullseye">Authorizing...</div>
  </section>
</template>

<script>
import network from '@/network.js';
import afterLogin from '@/lib/after-login.js';

export default {
  name: 'AuthorizeScreen',

  async created() {
    const token = this.$route.params.token;

    const { header, promptUsername, userId, username } = await network.auth.confirmEmailToken(
      token,
      this.$store.state.lastLocation
    );

    this.$store.commit('setUser', userId);
    this.$store.commit('setAuth', header);

    if (promptUsername) {
      this.$router.push('/username');
      return;
    } else {
      this.$store.commit('setUsername', username);
      await afterLogin(this.$router);
      return;
    }
  },
};
</script>
