// https://thomashunter.name/posts/2021-12-11-detecting-if-pwa-twa-is-installed

const GOOGLE_MAP = 'https://www.google.com/maps?q=';
const APPLE_MAP = 'https://maps.apple.com/?q=';

const UA = navigator.userAgent;

const IOS = UA.match(/iPhone|iPad|iPod/);
const ANDROID = UA.match(/Android/);

export const PLATFORM = IOS ? 'ios' : ANDROID ? 'android' : 'unknown';

export const STANDALONE = window.matchMedia('(display-mode: standalone)').matches;

// TODO: iPad Pros don't have the string "iPad" in their user agent.
// For now forcing anything with "Macintosh" to be set as installed.
// This means users on an apple computer will be marked as installed.

// The installed TWA on iPhone does not contain the string "Safari"
export const INSTALLED = !!(STANDALONE || (IOS && !UA.match(/Safari/)) || UA.match(/Macintosh/));

export const MAP_URL = IOS ? APPLE_MAP : GOOGLE_MAP;

// These query params are set by the TWA upon install from app store
// Retaining them after reloading page, not that they're currently used
export const ROOT_URL = INSTALLED ? `/?app&platform=${PLATFORM}` : '/';

export const IS_APPLE = !ANDROID && UA.match(/Macintosh/);

console.log(`INSTALLED=${INSTALLED} PLATFORM=${PLATFORM} ROOT_URL=${ROOT_URL}`);
