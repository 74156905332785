<template>
  <section class="screen simple-message">
    <div>Redirecting...</div>
  </section>
</template>

<script>
export default {
  name: 'RootScreen',

  async created() {
    if (!this.$store.state.tutorial) {
      this.$router.replace('/tutorial');
      return;
    } else if (this.$store.state.user) {
      this.$router.replace('/map/home');
      return;
    } else {
      this.$router.replace('/map/suggested');
      return;
    }
  },
};
</script>
