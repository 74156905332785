<template>
  <va-app-bar v-if="visible">
    <va-button :disabled="!displayBack" icon="chevron_left" color="#fff" flat :rounded="false" @click="onClickBack" />

    <div class="heading">
      {{ title }}<span v-if="subtitle">: {{ subtitle }}</span>
    </div>

    <va-spacer />

    <va-icon v-if="!$store.state.online" name="cloud_off" class="mr" />
    <va-icon v-if="!$store.state.gpsIsOkay" name="gps_off" class="mr" />

    <va-button v-if="showMenuButton" icon="menu" color="#fff" flat :rounded="false" @click="onClickMenu" />
  </va-app-bar>
</template>

<script>
export default {
  name: 'HeaderBar',

  setup() {},

  computed: {
    visible() {
      return !!this.$store.state.header;
    },

    title() {
      return this.$store.state.headerTitle;
    },

    subtitle() {
      return this.$store.state.headerSubtitle;
    },

    displayBack() {
      // if the navbar is visible the screen allows navigation
      return !!this.$store.state.navbar;
    },

    showMenuButton() {
      return !!this.$store.state.showMenuButton;
    },
  },

  data() {
    return {};
  },

  methods: {
    onClickBack() {
      this.$router.back();
    },

    onClickMenu() {
      this.$store.commit('toggleMenu');
    },
  },
};
</script>

<style scoped>
.va-app-bar {
  height: var(--nav-bar-height);
  overflow: hidden;
  background-color: #000 !important;
  color: white;
  padding: 0 8px;
  z-index: 1; /* On the map view screen the map seems to intercept clicks on the bottom half of the menu button without this z-index */
}
.heading {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 8px - 8px - 36px - 36px);
}
.mr {
  margin-right: 6px;
}
</style>
