import store from '@/store.js';

const ROOT = process.env.NODE_ENV === 'production' ? (
  location.host.includes('mapbuddy') ? 'https://api.mapbuddy.app' : 'https://api.radar.chat'
) : 'http://localhost:9008';

// Despite Apple calling this a "Redirect", it refers to the URL that is permitted to interact with the Apple auth popup
// TODO: Temporary while both Radar Chat and Map Buddy are used
const APPLE_REDIRECT_RC =
  process.env.NODE_ENV === 'production'
    ? 'https://app.radar.chat/account-create/apple'
    : 'https://app.radar.chat/account-create-local/apple';
const APPLE_REDIRECT_MB =
  process.env.NODE_ENV === 'production'
    ? 'https://app.mapbuddy.app/account-create/apple'
    : 'https://app.mapbuddy.app/account-create-local/apple';

async function request(path, method = 'GET', body, includeAuth = true) {
  const headers = {};

  if (body) {
    headers['Content-Type'] = 'application/json';
  }

  if (includeAuth && store.state.authHeader) {
    headers['Authorization'] = store.state.authHeader;
  }

  headers['X-Radar-Session'] = store.state.session;

  const result = await fetch(`${ROOT}${path}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });

  const payload = await result.json();

  if (result.status === 401 && payload?.error?.code === 'invalid_auth') {
    console.error('User has been logged out');
    store.commit('setLoginExpired');
    // TODO: Never resolve the promise since page will refresh?
  }

  if (!result.ok) {
    console.error(result.status, payload);
    const error = new Error(payload.error.message);
    error.code = payload.error.code;
    error.status_code = result.status;
    throw error;
  }

  return payload;
}

export default {
  APPLE_REDIRECT: location.host.includes('mapbuddy')
    ? APPLE_REDIRECT_MB
    : APPLE_REDIRECT_RC,

  auth: {
    async generateAuthEmail(email, coord) {
      const payload = await request(
        `/v2/auth/emailtoken`,
        'POST',
        {
          email,
          lat: coord.lat,
          lon: coord.lon,
        },
        false
      );

      return !!payload.ok;
    },

    async confirmEmailToken(code, coord) {
      const payload = await request(
        `/v2/auth/confirm`,
        'POST',
        {
          code,
          lat: coord.lat,
          lon: coord.lon,
        },
        false
      );

      return {
        userId: payload.user_id,
        header: payload.authorization_header,
        promptUsername: !!payload.using_default_username,
        username: payload.username,
      };
    },

    async googleIdentity(jwt, coord) {
      const payload = await request(
        `/v2/auth/google-identity`,
        'POST',
        {
          jwt,
          lat: coord.lat,
          lon: coord.lon,
        },
        false
      );

      return {
        userId: payload.user_id,
        header: payload.authorization_header,
        promptUsername: !!payload.using_default_username,
        username: payload.username,
      };
    },

    async appleIdentity(code, coord) {
      const payload = await request(
        `/v2/auth/apple-identity`,
        'POST',
        {
          code,
          lat: coord.lat,
          lon: coord.lon,
        },
        false
      );

      return {
        userId: payload.user_id,
        header: payload.authorization_header,
        promptUsername: !!payload.using_default_username,
        username: payload.username,
      };
    },
  },

  batch: {
    // This endpoint is a combination of map.get() and post.list()
    async mapGetPostsList(mapName, coord, timeRange) {
      const payload = await request(
        `/v2/batch/map-and-posts/${mapName}?lat=${coord.lat}&lon=${coord.lon}&timerange=${timeRange}`,
        'GET'
      );

      return payload;
    },

    async postGetRepliesList(mapName, postId) {
      const payload = await request(`/v2/batch/post-and-replies/${mapName}/${postId}`, 'GET');

      return payload;
    },
  },

  map: {
    async get(name) {
      const payload = await request(`/v2/maps/${name}`, 'GET');

      return payload;
    },

    async create(name, listable, password, admin, description, icon, capabilities) {
      if (!store.state.user) {
        throw new Error('cannot create a map without being logged in');
      }

      const payload = await request(`/v2/maps`, 'POST', {
        name,
        listable,
        password,
        non_member_read: true,
        non_member_write: false,
        member_write: !admin,
        description,
        icon,
        capabilities,
      });

      return payload;
    },

    async search(searchTerm) {
      const params = new URLSearchParams({
        search: searchTerm,
      });

      const payload = await request(`/v2/maps?${params}`, 'GET');

      return payload;
    },

    async suggest(coord) {
      const payload = await request(`/v2/maps?suggest&lat=${coord.lat}&lon=${coord.lon}`, 'GET');

      return payload;
    },

    async update(name, data) {
      if (!store.state.user) {
        throw new Error('cannot update a map without being logged in');
      }

      const payload = await request(`/v2/maps/${name}`, 'PATCH', data);

      return payload;
    },
  },

  membership: {
    async subscribe(mapName, password) {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to join map without being logged in');
      }

      const payload = await request(`/v2/maps/${mapName}/members`, 'POST', {
        user_id: userId,
        password,
        level: 2,
      });

      return payload;
    },

    async unsubscribe(mapName) {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to leave map without being logged in');
      }

      const payload = await request(`/v2/maps/${mapName}/members/${userId}`, 'DELETE');

      return payload;
    },
  },

  post: {
    async create(message, level, coord, map, nsfw, replies, permanent, placeId, icon) {
      if (!store.state.user) {
        throw new Error('cannot create a post without being logged in');
      }

      const payload = await request(`/v2/maps/${map}/posts`, 'POST', {
        message,
        lat: coord.lat,
        lon: coord.lon,
        level,
        nsfw,
        replies,
        favorite: true, // auto favoriting all newly created posts for now
        permanent,
        place_id: placeId,
        icon,
      });

      return payload;
    },

    async list(map, coord, timeRange) {
      const payload = await request(
        `/v2/maps/${map}/posts?lat=${coord.lat}&lon=${coord.lon}&timerange=${timeRange}`
      );

      return payload;
    },

    async listBeacon(map, coord, width, height, timeRange) {
      const payload = await request(
        `/v2/maps/${map}/posts?type=beacon&lat=${coord.lat}&lon=${coord.lon}&width=${width}&height=${height}&timerange=${timeRange}`
      );

      return payload;
    },

    async delete(map, postId) {
      if (!store.state.user) {
        throw new Error('cannot delete a post without being logged in');
      }

      const payload = await request(`/v2/maps/${map}/posts/${postId}`, 'DELETE');

      return payload;
    },

    async report(map, postId, coord, reason) {
      const payload = await request(`/v2/maps/${map}/posts/${postId}/reports`, 'POST', {
        lat: coord.lat,
        lon: coord.lon,
        reason: reason,
      });

      return payload;
    },
  },

  thread: {
    async listFavorites() {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to list favorite posts without being logged in');
      }

      const payload = await request(`/v2/users/${userId}/favorites`);

      return payload;
    },

    async favoritePost(map, postId) {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to favorite a post without being logged in');
      }

      const payload = await request(`/v2/maps/${map}/posts/${postId}/favorite`, 'POST', {
        user_id: userId,
      });

      return payload;
    },

    async unfavoritePost(map, postId) {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to unfavorite a post without being logged in');
      }

      const payload = await request(`/v2/maps/${map}/posts/${postId}/favorite/${userId}`, 'DELETE');

      return payload;
    },

    async postReply(map, postId, message) {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to post a post reply without being logged in');
      }

      const payload = await request(`/v2/maps/${map}/posts/${postId}/replies`, 'POST', {
        message,
      });

      return payload;
    },

    async deleteReply(map, postId, replyId) {
      const payload = await request(`/v2/maps/${map}/posts/${postId}/replies/${replyId}`, 'DELETE');

      return payload;
    },
  },

  user: {
    async setUsername(username) {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to update username without being logged in');
      }

      const payload = await request(`/v2/users/${userId}`, 'PUT', {
        username,
      });

      return payload;
    },

    async listSubscribed() {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to list subscribed maps without being logged in');
      }

      const payload = await request(`/v2/users/${userId}/subscribed`, 'GET');

      return payload;
    },

    async getSelf() {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to list subscribed maps without being logged in');
      }

      const payload = await request(`/v2/users/${userId}`, 'GET');

      return payload;
    },

    async get(username) {
      const payload = await request(`/v2/users/${username}?byusername`, 'GET');

      return payload;
    },

    async deleteSelfAccount() {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to delete self without being logged in');
      }

      const payload = await request(`/v2/users/${userId}`, 'DELETE');

      return payload;
    },
  },

  checkin: {
    async checkin(map, postId, coord) {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to checkin without being logged in');
      }

      const payload = await request(`/v2/maps/${map}/posts/${postId}/checkins`, 'POST', {
        lat: coord.lat,
        lon: coord.lon,
      });

      return payload;
    },

    async getMyCheckins() {
      const userId = store.state.user;
      if (!userId) {
        throw new Error('attempted to get self checkins without being logged in');
      }

      const payload = await request(`/v2/users/${userId}/checkins`, 'GET');

      return payload;
    },
  },

  qr: {
    async get(code) {
      const payload = await request(`/v2/qr/${code}`);

      return payload;
    },
  },

  place: {
    async search(term, coord) {
      const payload = await request(`/v2/place/search?term=${term}&lat=${coord.lat}&lon=${coord.lon}`);

      return payload;
    },

    async lookup(placeId) {
      const payload = await request(`/v2/place/lookup/${placeId}`);

      return payload;
    },
  },

  misc: {
    async getLevels() {
      const payload = await request(`/v2/levels`, 'GET', null, false);

      if (payload.beacon && !payload['6']) {
        payload['6'] = payload.beacon;
      }

      return payload;
    },

    /**
     * The accuracy of this is abysmal. Thinks that a hardline in SF is in Foster City,
     * and a cell phone in SF is in Los Vegas. That said, it might be better than nothing?
     */
    async getGeoIp() {
      const payload = await request(`/v2/geoip`, 'GET', null, false);

      console.log(`made a geoip lookup for ${payload.ip}`);

      return payload.geo;
    },
  },
};
